import { extractStyleValueElseNull, getEditorColor } from "./ColorUtil";
import { Constants } from "./Constants";
import Image from "next/image";
import { domains } from "./DomainsUtil";
import { LRUCache } from 'lru-cache'

export const isLinkAbsolute = (data) => {
    return (new RegExp('^(?:[a-z+]+:)?//', 'i')).test(data)
}

export const getTarget = (url) => {
    if(!url){
        return
    }
    
    return isLinkAbsolute(url) ? "_blank" : "";
}

export const getValueForPopularAndRelatedBlock = (items, type) => {
    let objects = [];

    if (typeof items === 'undefined'){
        return []
    }

    items.forEach(item => {
        if (!item['related_content_block_type'] || !item['related_content_block_type']['field_related_content_type']){
            return []
        }

        // if (item['related_content_block_type']['field_related_content_type'][0].value == type){
        if (isItUndefined(item['related_content_block_type']['field_related_content_type']) == type){
            objects.push(item['related_content_block_type'])
        }
    })

    return objects;
}

export const isBlockPopular = (block) => {
    return block[0]?.field_related_content_type[0]?.value === '1'
}

export const isBlockRelated = (block) => {
    return block[0]?.field_related_content_type[0]?.value === '0'
}

export const assemble = (arr) => {
    if (!arr || !Array.isArray(arr) || arr?.length == 0){
        return
    }

    let hierarchy = arr?.map(obj=>({...obj}));

    for(let i=0;i<hierarchy.length;i++){
        const cur=hierarchy[i];
        if(cur.parent_id){
            const parent = hierarchy.find(({id})=>id===cur.parent_id);
            if (!parent){
                continue
            }
            parent.children = [...(parent?.children || []), cur];
        }
    }

    hierarchy=hierarchy.filter(({parent_id})=>!parent_id);

    return hierarchy;
}

export const retCountTitle = (width, mobile = null, tabletLaptop = null) => {
    if (width < 768){
        return mobile ? mobile : 50
    }else if(width < 1220){
        return tabletLaptop ? tabletLaptop : 80
    }

    return 48
}

export const retCountText = (width) => {
    if (width < 768){
        return 50
    }else if(width < 1220){
        return 180
    }

    return 100
}

export const replaceGlobalMetrics = (text, global) => {
    let keys = Object.keys(global);
    let sendText = ''

    if(!text){
        return;
    }

    keys.forEach(item => {
        if ('[[' + item + ']]' !== text){
            return
        }

        if (typeof global[item] === 'object'){
            let values = '';

            global[item].forEach((value, index) => {
                values += '<span>' + value?.value + '</span>'
            })

            sendText += text.replace('[[' + item + ']]', values)
        }else {
            sendText = text.replace('[[' + item + ']]', global[item])
        }
    })

    if (sendText === ''){
        return text
    }

    return sendText;
}

export const truncateText = (text, length, endText) => {
    if (!text){
        return ''
    }

    if (text.length <= length){
        return text
    }

    return text.substring(0, length) + endText
}

export const stripHtml = (text) => {
    if(!text){
        return;
    }

    return text.replace(/<[^>]*>?/gm, '');
}

export const removeNbsp = (text) => {
    if(!text) return;
    return text.replace(/&nbsp;/g, ' ');
}

export const findValueByKey = (name, blocks) => {
    let ret = {};
    if(!blocks || blocks.length == 0 || !blocks.length) return;
    blocks?.forEach(function(item) {
        Object.keys(item).forEach(function(key) {
            if (key !== name){
                return
            }

            ret = item[key];
        });
    });

    return ret;
}

export const returnPrimaries = (data) => {
    let arr = []

    if (!data){
        return []
    }

    data.forEach(item => {
        if (!item?.field_template_category || !item?.field_template_category[0]){
            return
        }

        arr.push(item.field_template_category[0].name)
    })

    return arr
}

export function isEmpty(obj) {
    for(var prop in obj) {
        if(Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }

    return JSON.stringify(obj) === JSON.stringify({});
}

export const findImageByKey = (name, sentKey, blocks) => {
    let ret = {};

    blocks?.forEach(function(item) {
        Object.keys(item).forEach(function(key) {

            if (key !== name){
                return
            }

            ret = item[key];
        });
    });
    return !isEmpty(ret) ? (ret[sentKey].length !== 0 && ret[sentKey].webimage ? ret[sentKey].webimage : '/images/placeholder.png') : '/images/placeholder.png';
}

export const findImageByKeyWebLarge = (name, sentKey, blocks) => {
    let ret = {};
    blocks?.forEach(function(item) {
        Object.keys(item).forEach(function(key) {
            if (key !== name){
                return
            }
            ret = item[key];
        });
    });
    return !isEmpty(ret) ? (ret[sentKey].length !== 0 && ret[sentKey].webimage ? ret[sentKey].webimage : '/images/placeholder.png') : '/images/placeholder.png';
}

export const findImageByKeyWithoutFallBack = (name, sentKey, blocks) => {
    let ret = {};
    blocks?.forEach(function(item) {
        Object.keys(item).forEach(function(key) {
            if (key !== name){
                return
            }
            ret = item[key];
        });
    });
    return !isEmpty(ret) ? (ret[sentKey].length !== 0 && ret[sentKey].webimage ? ret[sentKey].webimage : '') : '';
}

export const getResourceContentImageSrc = (data) => {
    if(findImageByKeyWithoutFallBack('hero_graphic_block_type', 'field_hero_image', data?.field_template_blocks)){
        return findImageByKeyWithoutFallBack('hero_graphic_block_type', 'field_hero_image', data?.field_template_blocks);
    }else if(findImageByKeyWithoutFallBack('hero_block', 'field_hero_image', data?.field_template_blocks)){
        return findImageByKeyWithoutFallBack('hero_block', 'field_hero_image', data?.field_template_blocks);
    }
    else if(findImageByKeyWithoutFallBack('template_banner_block_type', 'field_web_small_image', data?.field_template_blocks)){
        return findImageByKeyWithoutFallBack('template_banner_block_type', 'field_web_small_image', data?.field_template_blocks);
    }else if(data?.field_facility_location){
        return generateLocationImageSrc();
    }

    return '/images/placeholder.png';
}

const options = {
    max: 5000,
  
    // for use with tracking overall storage size
    maxSize: 3000000,
    sizeCalculation: (value, key) => {
      return 1
    },
  
    // for use when you need to clean up something when objects
    // are evicted from the cache
    dispose: (value, key) => {
    //   freeFromMemoryOrWhatever(value)
    },
  
    // how long to live in ms
    ttl: 1000 * 60 * 10,
  
    // return stale items before removing from cache?
    allowStale: false,
  
    updateAgeOnGet: false,
    updateAgeOnHas: false,
  
    // async method to use for cache.fetch(), for
    // stale-while-revalidate type of behavior
    fetchMethod: async (
      key,
      staleValue,
      { options, signal, context }
    ) => {},
  }
  
  const cache = new LRUCache(options)
  

export const fetchData = async (endpoint, url) => {    
    // Generate a cache key based on the endpoint URL and the requested URL
    const cacheKey = endpoint.baseApiUrl + url;

    // Check if the data exists in the cache
    const cachedData = cache.get(cacheKey);
    if (cachedData) {
        return cachedData;
    }

    try {
        // Fetch data from the API
        const res = await fetch(cacheKey);
        if (!res.ok) {
            throw new Error('Failed to fetch data');
        }

        // Parse response JSON
        const jsonData = await res.json();

        // Store data in the cache
        cache.set(cacheKey, jsonData);

        return jsonData;
    } catch (error) {
        return null; // Return null or handle error as per your requirement
    }
}

export const fetchDataApiV2 = async (endpoint, url) => {
    if(url.includes("resetandsecretdlr4sDfT")){
        cache.clear()
        return 'CC';
    }
    if(url.includes("dlrpreview")){
        const res = await fetch(endpoint.apiV2Url + url, Constants.headers);
        if(!res.ok || !res){
            return {status:res.status};
        }
        return await res.json();
    }

    // Generate a cache key based on the endpoint URL and the requested URL
    const cacheKey = endpoint.apiV2Url + url;

    // Check if the data exists in the cache
    const cachedData = cache.get(cacheKey);
    if (cachedData) {
        return cachedData;
    }

    try {
        // Fetch data from the API
        const res = await fetch(cacheKey, Constants.headers);
        if (!res.ok) {
            throw new Error('Failed to fetch data');
        }

        // Parse response JSON
        const jsonData = await res.json();

        // Store data in the cache
        cache.set(cacheKey, jsonData);

        return jsonData;
    } catch (error) {
        const res = await fetch(endpoint.apiV2Url + url, Constants.headers);
        if(!res.ok || !res){
            return {status:res.status};
        }
        return await res.json();
    }
}

export const fetchDataFromUrl = async (url) => {
    const res = await fetch(url);
    return await res.json();
}

export const replaceOnDocument = (pattern, string, {target = document.body} = {}) => {
    // Handle `string` — see the last section
    [
        target,
        ...target.querySelectorAll("*:not(script):not(noscript):not(style)")
    ].forEach(({childNodes: [...nodes]}) => nodes
        .filter(({nodeType}) => nodeType === document.TEXT_NODE)
        .forEach((textNode) => textNode.textContent = textNode.textContent.replace(pattern, string)));
};

export const removeTags = (string) => {
    if((string === null)|| (string === '')){
        return false;
    }else {
        string = string.toString();
    }

    return string.replace( /(<([^>]+)>)/ig, '');
}

export const containsSpecialChars = (str) => {
    const specialChars = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialChars.test(str);
}

export const applyTruncationForScreen = (value,desktopLength, tabletLength, mobileLength, laptopDesktopLength, xxlDesktop) => {
    let docWidth = 0;

    if (typeof document !== 'undefined'){
        docWidth = document.getElementsByTagName('main')[0]?.offsetWidth
    }

    if(!value || !desktopLength || !tabletLength || !mobileLength){
        return;
    }

    if(docWidth > 1440) {
        return truncateText(value, xxlDesktop, '...');
    }

    if(docWidth <= 1440) {
        return truncateText(value, desktopLength, '...');
    }

    if(docWidth >= 576 && docWidth < 1024){
        return truncateText(value, tabletLength, '...');
    }

    if(docWidth >= 1024 && docWidth < 1250){
        return truncateText(value, laptopDesktopLength, '...');
    }

    if(docWidth < 576){
        return truncateText(value, mobileLength, '...');
    }

    return truncateText(value, desktopLength, '...');
}

export const applyTruncationForAllScreen = (value,desktopLength, tabletLaptopLength, mobileLength, mobileTablet, tabletLength, desktopLaptopLength, width) => {
    if(!value || !desktopLength || !tabletLaptopLength || !mobileLength || !mobileTablet || !tabletLength || !desktopLaptopLength){
        return;
    }

    if(width < 417){
        return truncateText(value, mobileLength, '...');
    }

    else if(width > 417 && width < 548){
        return truncateText(value, mobileTablet, '...');
    }

    else if(width >= 548 && width < 752){
        return truncateText(value, tabletLength, '...');
    }

    else if(width >= 752 && width < 1024){
        return truncateText(value, tabletLaptopLength, '...');
    }

    else if(width >= 1024 && width < 1220){
        return truncateText(value, desktopLaptopLength, '...');
    }

    return truncateText(value, desktopLength, '...');
}

export const correctDomainForLanguage = (url, host = '') => {
    if (!url || url === '') {
        return '';
    }

    const regex = /\/(.*?)\//;
    let domainName = '';
    let language = url.match(regex);

    switch (true) {
        case host.includes('localhost'):
            domainName = 'localhost';
            // domainName = 'uat';
            break;
        case host.includes('dlr'):
            domainName = 'dlr';
            break;
        case host.includes('uat'):
            domainName = 'uat';
            break;
        case '/asia/':
            return Constants.asia;
            break;
        case '/nl/':
            return Constants.nl;
            break;    
        default:
            domainName = 'production';
    }

    const domainsLocal = domains;
    const domainForLanguage = domainsLocal.find(domain => domain[language[1]]);

    if (domainForLanguage) {
        return domainForLanguage[language[1]][domainName];
    }

    return '';
}

export const correctUrlFormatForRouting = (url) => {
    let language = '';
    if(!url || url == ''){
        return;
    }

    // let firstTenCharactersOfUrl = url.slice(0, 8);
    let firstTenCharactersOfUrl = url.slice(0, 10);

    if(firstTenCharactersOfUrl.includes('/fr/')){
        language = '/fr/'
        url = url.substring(3)
    }else if(firstTenCharactersOfUrl.includes('/de/')){
        language = '/de/'
        url = url.substring(3)
    }else if(firstTenCharactersOfUrl.includes('/at/')){
        language = '/at/'
        url = url.substring(3)
    }else if(firstTenCharactersOfUrl.includes('/hr/')){
        language = '/hr/'
        url = url.substring(3)
    }else if(firstTenCharactersOfUrl.includes('/dk/')){
        language = '/dk/'
        url = url.substring(3)
    }else if(firstTenCharactersOfUrl.includes('/ie/')){
        language = '/ie/'
        url = url.substring(3)
    }else if(firstTenCharactersOfUrl.includes('/nl/')){
        language = '/nl/'
        url = url.substring(3)
    }else if(firstTenCharactersOfUrl.includes('/es/')){
        language = '/es/'
        url = url.substring(3)
    }else if(firstTenCharactersOfUrl.includes('/se/')){
        language = '/se/'
        url = url.substring(3)
    }else if(firstTenCharactersOfUrl.includes('/ch/')){
        language = '/ch/'
        url = url.substring(3)
    }else if(firstTenCharactersOfUrl.includes('/el/')){
        language = '/el/'
        url = url.substring(3)
    }else if(firstTenCharactersOfUrl.includes('/en-gb/')){
        language='/en-gb/'
        url = url.substring(6)
    }else if(firstTenCharactersOfUrl.includes('/en-us/')){
        language = '/en-us/'
        url = url.substring(6)
    }else if(firstTenCharactersOfUrl.includes('/asia/')){
        language = '/asia/'
        url = url.substring(5)
    }else if(firstTenCharactersOfUrl.includes('/ko/')){
        language = '/ko/'
        url = url.substring(3)
    }else if(firstTenCharactersOfUrl.includes('/zh-hans/')){
        language = '/cn/'
        url = url.substring(8)
    }else if(firstTenCharactersOfUrl.includes('/be/')){
        language = '/be/'
        url = url.substring(3)
    }

    return {'url':url, 'language':language };
}

export const tempRender = (item) => {
    let result = [];

    if(!item){
        return;
    }

    if(typeof item === 'string'){
        result.push(
            <span>{item}</span>
        )
    }else {
        for(let i of item){
            result.push(
                <span>{i + ', '}</span>
            )
        }
    }

    if(typeof item !== 'string'){
        result.pop()
        result.push(item[item.length - 1])
    }

    return result;
}

export const getHoverColorLocation = (color) => {
    if(!color){
        return;
    }

    let filter;

    switch (color) {
        case 'none':
            filter = 'invert(37%) sepia(91%) saturate(6492%) hue-rotate(351deg) brightness(91%) contrast(130%)';
            break;
        case 'americas':
            filter = 'invert(9%) sepia(95%) saturate(7257%) hue-rotate(254deg) brightness(92%) contrast(137%)';
            break;
        case 'blue':
            filter = 'invert(9%) sepia(95%) saturate(7257%) hue-rotate(254deg) brightness(92%) contrast(137%)';
            break;
        case 'emea':
            filter = 'invert(9%) sepia(93%) saturate(6458%) hue-rotate(274deg) brightness(107%) contrast(119%)';
            break;
        case 'apac':
            filter = 'invert(15%) sepia(27%) saturate(5436%) hue-rotate(165deg) brightness(98%) contrast(99%)';
            break;
        case 'white':
            filter = 'invert(100%) sepia(100%) saturate(1%) hue-rotate(304deg) brightness(105%) contrast(102%)';
            break;
        case 'green':
            filter = 'invert(69%) sepia(74%) saturate(3067%) hue-rotate(108deg) brightness(96%) contrast(102%)';
            break;
        case 'violet':
            filter = 'invert(12%) sepia(96%) saturate(6734%) hue-rotate(273deg) brightness(87%) contrast(120%)';
            break;
        case 'amber':
            filter = 'invert(78%) sepia(90%) saturate(5185%) hue-rotate(1deg) brightness(101%) contrast(101%)';
            break;
        case 'light-amber':
            filter = 'invert(84%) sepia(41%) saturate(392%) hue-rotate(346deg) brightness(104%) contrast(105%)';
            break;
        case 'light-slate-grey':
            filter = 'invert(79%) sepia(22%) saturate(229%) hue-rotate(140deg) brightness(87%) contrast(85%)';
            break
        case 'cyan':
            filter = 'invert(66%) sepia(13%) saturate(5759%) hue-rotate(138deg) brightness(106%) contrast(102%)';
            break;
        case 'light-blue':
            filter = 'invert(72%) sepia(12%) saturate(5861%) hue-rotate(191deg) brightness(102%) contrast(103%)';
            break;
        case 'light-violet':
            filter = 'invert(69%) sepia(27%) saturate(6019%) hue-rotate(216deg) brightness(113%) contrast(94%)';
            break;
        case 'light-cyan':
            filter = 'invert(100%) sepia(40%) saturate(6154%) hue-rotate(163deg) brightness(103%) contrast(98%)';
            break;
        case 'slate-grey':
            filter = 'invert(19%) sepia(10%) saturate(6558%) hue-rotate(151deg) brightness(99%) contrast(99%)'
    }
    return filter;
}

export const getRegionIcon = (color) => {
    if(!color){
        return '/images/checked-circle-americias.svg';
    }

    let icon;
    switch (color) {
        case 'americas':
            icon = '/images/checked-circle-americias.svg';
            break;
        case 'emea':
            icon = '/images/checked-circle-emea.svg';
            break;
        case 'apac':
            icon = '/images/checked-circle-apac.svg';
            break;
        case '#FF0000':
            icon = '/images/checked-circle-none.svg';
            break;
        case 'violet':
            icon = '/images/checked-circle-violet.svg';
            break;
        case 'light-violet':
            icon = '/images/checked-circle-light-violet.svg';
            break;
        case 'cyan':
            icon = '/images/checked-circle-cyan.svg';
            break;
        case 'blue':
            icon = '/images/checked-circle-americias.svg';
            break;
        case 'amber':
            icon = '/images/checked-circle-amber.svg';
            break;
    }

    return icon;
}

export const isMobile = (width) => {
    if(!width){
        return;
    }

    return width < 576;
}

export const isTablet = (width) => {
    if(!width){
        return;
    }

    return width >= 576 && width < 1024;
}

export const isLaptop = (width) => {
    if(!width){
        return;
    }

    return width >= 1024 && width <= 1440;
}

export const isBigScreen = (width) => {
    if(!width){
        return;
    }
    return width >= 1024 && width < 1920;
}

export const isDesktop = (width) => {
    if(!width){
        return;
    }

    return width > 1440;
}

export const isHD = (width) => {
    if(!width){
        return;
    }

    return width >= 1920;
}

export const isGrayScaleOrColor = (data) => {
    if (data === '1'){
        return 'color'
    }else if (data === '0'){
        return 'grayscale'
    }
}

export const textAlignMarginLeft = (data) => {
    if (data === '0'){
        return '0'
    }else if (data === '1'){
        return 'auto'
    }else if (data === '2'){
        return 'auto'
    }
}

export const textAlign = (data) => {
    if (data === '0'){
        return 'left'
    }else if (data === '1'){
        return 'center'
    }else if (data === '2'){
        return 'right'
    }
}

export const textAlignMarginRight = (data) => {
    if (data === '0'){
        return 'auto'
    }else if (data === '1'){
        return 'auto'
    }else if (data === '2'){
        return '0'
    }
}

export const correctInternalLink = (link) => {
    if(!link) return;

    if(link.includes('internal')){
        return link.substring(9);
    }
    return link;
}

export function isItUndefined(data, prop = 'value'){
    if(!data){
        return;
    }

    return data[0]?.[prop];
}

export const renderTextColor = (data) => {
    if(!data){
        return;
    }

    return data;
}

export const getLowerCase = (value) => {
    if(!value) return;

    return value.toLowerCase();
}

export const countFacilityForMetro = (metro, facilities) => {
    if(!facilities.length || !metro) return;

    let result = [];

    facilities.map(item => {
        if(metro == item.metro){
            result.push(item)
        }
    })

    return result.length;
}

export const getFacilityForMetro = (metro, facilities) => {
    if(facilities?.length == 0 || !metro) return;

    let result = [];

    facilities?.map(item => {
        if(metro == item.metro){
            result.push(item)
        }
    })

    return result;
}

export const removeDuplicates = (arr) => {
    if(!arr || arr.length == 0) return;

    return arr.filter((item,
        index) => arr.indexOf(item) === index);
}

export const getQuoteContainerClass = (children) => {
    if(isItUndefined(children?.field_template_quote_name) || isItUndefined(children?.field_template_quote_job)){
        return 'container full';
    }

    return 'container';
}

export const marketoOptions = {
    method: "GET",
    headers: {
        'Accept': "application/json",
        "Content-Type": "application/json;charset=UTF-8",
        // 'X-Algolia-Application-Id' : 'SWULV5ONQ5',
        'X-Algolia-Application-Id' : 'O2VJB2KMPB',
        // 'X-Algolia-API-Key' : 'b40f6992ed8053d31458f4f888b9a8cd'
        'X-Algolia-API-Key' : '7f803c1646224f8ba2a498dbb4a8b027'
    }
};

export const removeClass = (className) => {
    const elements = document.querySelectorAll(`.${className}`);
    elements.forEach(element => {
      element.classList.remove(className);
    });
}

export const getAlgoliaDatasetValue = (data) => {
    switch (Number(data)){
        case 0:
            return 'drupal'
            // return 'drupalprod'
        case 1:
            return 'related'
            // return 'relatedprod'
        case 2:
            return 'locations'
            // return 'locationsprod'
        case 3:
            return 'press_releases_'
        default:
            return 'drupal'
            // return 'drupalprod'
    }
}

export const getAlgoliaIndex = () => {
    if (typeof window !== 'undefined') {
        const host = window.location.host;

        switch (true){
            case host.includes('localhost'):
                // return 'drupal'
                return 'dev'
            case host.includes('dlr'):
                // return 'related'
                return 'dev'
            case host.includes('uat'):
                // return 'locations'
                return 'uat'
            case host.includes('digitalrealty'):
                return 'prod'
            default:
                // return 'drupal'
                return 'prod'
        }
    }
}

export const getHoverEffectValue = (data) => {
    switch (data){
        case '0':
            return 'greyscale'
        case '1':
            return 'color'
        default:
            return 'color'
    }
}

export const createFilterData = (lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, lng, dataset) => {
    if(!facetFilters){
        return;
    }

    facetFilters.forEach(function(item, i) {
        if(item === 'English'){
            lngArray.push('en')
            return
        }

        if(item === 'English (UK)'){
            lngArray.push('en-uk')
            return
        }

        if(item === 'English (US)'){
            lngArray.push('en-us')
            return
        }

        if(item === 'Dutch'){
            lngArray.push('nl')
            return
        }

        if(item === 'French'){
            lngArray.push('fr')
            return
        }

        if(item === 'German'){
            lngArray.push('de')
            return
        }

        if(!item.includes("search_api_language:") && !facetFilters[i].includes("category_name:")) {
            item = item.replace("&amp;", "%26");

            if(item){
                facetFiltersArray.push(JSON.stringify("primary_source_language:" + item))
                facetFiltersArray.push(JSON.stringify("secondary_source_language:" + item))
                facetFiltersArray.push(JSON.stringify("system_source_language:" + item))
                facetFiltersArray.push(JSON.stringify("content_type_original:" + item))
            }
        }
    });

    let tempLng = [];

    if(lngArray.length >0){
        lngArray.forEach(function(item){
            tempLng.push("search_api_language:" + item)
        })
    }else{
        tempLng.push("search_api_language:" + lng)
    }

    tempLng = JSON.stringify(tempLng);

    if(facetFiltersArray.length === 0){
        facetFiltersArray = JSON.stringify([]);
    }

    if(facetFiltersArray !== '[]'){
        return Constants.algoliaProduction + dataset + '?query=' + encodeURIComponent(query) + '&facetFilters=[[' + facetFiltersArray + '],' + tempLng + ',["status:true"]]&hitsPerPage=' + hitsPerPage + '&page=' + page;
    }else {
        return Constants.algoliaProduction + dataset + '?query=' + encodeURIComponent(query) + '&facetFilters=[' + facetFiltersArray + ',' + tempLng + ',["status:true"]]&hitsPerPage=' + hitsPerPage + '&page=' + page;
    }
}

export const createFilterDataProductFamily = (lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, lng, dataset, categoryName) => {
    if(!facetFilters){
        return;
    }

    let tempArr = [];
    let array1 = [];
    let array2 = [];
    let array3 = [];
    let array4 = [];
    let array5 = [];

    facetFilters.forEach(function(item, i) {
        if(item === 'Global'){
            lngArray.push('en')
            return
        }

        if(item === 'English (UK)'){
            lngArray.push('en-uk')
            return
        }

        if(item === 'English (US)'){
            lngArray.push('en-us')
            return
        }

        if(item === 'Dutch'){
            lngArray.push('nl')
            return
        }

        if(item === 'French'){
            lngArray.push('fr')
            return
        }

        if(item === 'German'){
            lngArray.push('de')
            return
        }

        tempArr.push(item)
    });

    for(let item of tempArr){
        if(item?.description.includes('Parent')){
            // array1.push('parent_name:' + item?.field_source_translation);
            array1.push('parent_name:' + item?.name);
        }

        if(item?.description.includes('Category')){
            // array2.push('category_name:' + item?.field_source_translation);
            array2.push('category_name:' + item?.name);
        }

        if(item?.description.includes('Compliance')){
            array3.push('primary_source_language:' + item?.field_source_translation);
        }

        if(item?.description.includes('Sustainability')){
            array4.push('secondary_source_language:' + item?.field_source_translation);
        }

        // if(item?.description.includes('Category')){
        //     array5.push('field_location_original:' + item?.field_source_translation);
        // }
    }

    if(array1.length !== 0){
        facetFiltersArray.push(JSON.stringify(array1))
    }

    if(array2.length !== 0){
        facetFiltersArray.push(JSON.stringify(array2))
    }

    if(array3.length !== 0){
        facetFiltersArray.push(JSON.stringify(array3))
    }

    if(array4.length !== 0){
        facetFiltersArray.push(JSON.stringify(array4))
    }

    let tempLng = [];

    if(lngArray.length > 0){
        lngArray.forEach(function(item){
            tempLng.push("search_api_language:" + item)
        })
    }else{
        tempLng.push("search_api_language:" + lng)
    }

    tempLng = JSON.stringify(tempLng);

    if(facetFiltersArray.length === 0){
        facetFiltersArray = JSON.stringify([]);
    }
 
    return Constants.algoliaProduction + dataset + '?query=' + encodeURIComponent(query) + '&facetFilters=[' + facetFiltersArray + ',' + tempLng + ',["status:true"]]&hitsPerPage=' + hitsPerPage + '&page=' + page;
}

export const createFilterPressReleases = (lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, lng, localFilter, dataset, categoryName) => {
    if(!facetFilters){
        return;
    }

    let tempArr = [];
    let array1 = [];
    let array2 = [];

    facetFilters.forEach(function(item, i) {
        if(item === 'Global'){
            lngArray.push('en')
            return
        }

        if(item === 'English (UK)'){
            lngArray.push('en-uk')
            return
        }

        if(item === 'English (US)'){
            lngArray.push('en-us')
            return
        }

        if(item === 'Dutch'){
            lngArray.push('nl')
            return
        }

        if(item === 'French'){
            lngArray.push('fr')
            return
        }

        if(item === 'German'){
            lngArray.push('de')
            return
        }

        tempArr.push(item)
    });

    for(let item of tempArr){
        array1.push('year:' + item);
    }

    if(array1.length !== 0){
        facetFiltersArray.push(JSON.stringify(array1))
    }

    if(localFilter === 'local'){
        array2.push('field_pr_original_language:' + lng);
    }else if(localFilter === 'global'){
        array2.push('field_pr_original_language:' + 'en');
    }

    if (array2.length !== 0) {
        facetFiltersArray.push(JSON.stringify(array2));
    }

    if(localFilter === 'global'){
        updateLanguageFilter(facetFiltersArray, lng)
    }

    let tempLng = [];

    if(lngArray.length > 0){
        lngArray.forEach(function(item){
            tempLng.push("search_api_language:" + item)
        })
    }else{
        tempLng.push("search_api_language:" + lng)
    }

    tempLng = JSON.stringify(tempLng);

    if(facetFiltersArray.length === 0){
        facetFiltersArray = JSON.stringify([]);
    }

    return Constants.algoliaProduction + dataset + '?query=' + encodeURIComponent(query) + '&facetFilters=[' + facetFiltersArray + ',' + tempLng + ',["status:true"]]&hitsPerPage=' + hitsPerPage + '&page=' + page;
}

const updateLanguageFilter = (filters, lng) => {
    const index = filters.indexOf('["field_pr_original_language:' + lng + '"]');

    if (index !== -1) {
        filters.splice(index, 1);
    }

    return filters;
}

export const getEditorColors = (...values) => {
    if (!values || !Array.isArray(values)) return;

    return values.map((value) => {
      return getEditorColor(value);
    });
};

export const getEditorColorsHex = (...values) => {
    if (!values || !Array.isArray(values)) return;

    return values.map((value) => {
      return extractStyleValueElseNull(value, 'color');
    });
 }

export const showPart = (...args) => {
    if(args.length === 0){
        return true;
    }

    return !args.every(function(item) { return item === undefined || item === '' });
}

export const increaseUntilDivisibleByThree = (num) => {
    let count = 0;
    while (num % 3 !== 0) {
      num++;
      count++;
    }
    return count;
}

const removeFromObject = (obj, paramToRemove = null) => {
    if(paramToRemove === null){
        for(let key in obj){
            if(key !== 'url'){
                delete obj[key];
            }
        }
    }

    for(let key in obj){
        if(key !== 'url'){
            if(typeof obj[key] === 'string' && obj[key] === paramToRemove){
                delete obj[key];
            }else if(Array.isArray(obj[key])) {
                obj[key] = obj[key].filter(item => item !== paramToRemove);

                if (obj[key].length === 0) {
                    delete obj[key];
                }
            }
        }
    }

    return obj;
}

const createQueryString = (query) => {
    for (const key in query) {
        if (key.includes("url")) {
            delete query[key];
        }
    }

    return query;
}

export const removeQueryParam = (router, child) => {
    if(!router){
        return;
    }

    const { pathname, query } = router;
    let url = router.asPath;

    if (child === undefined) {
        removeFromObject(query);
    } else {
        const name = (typeof child === 'string') ? child.toLowerCase() : (child?.name ?? '').toLowerCase();
        removeFromObject(query, name);
    }    

    router.replace({ 
        pathname: url.split('?')[0], 
        query: createQueryString(query)
    },
        undefined, { shallow: true }
    );
};

export const addQueryParamWithoutRedirecting = (router, url, queryParamType, filterName) => {
    const queryString = window.location.search;
    const { pathname, query } = router;
    let params = [];

    if (queryString) {
        const paramPairs = queryString.slice(1).split('&');

        for (const pair of paramPairs) {
            const [name, value] = pair.split('=');

            // if (name !== 'tethered') {
            params.push({ name, value });
            // }
        }
    }

    params.push({ name: filterName, value: queryParamType.toLowerCase() });
    const newQueryString = params.map(({ name, value }) => `${name}=${value.replace(/&/g, '%26').replace(/ /g, '+')}`).join('&');

    router.push(
        {
            pathname: url,
            query: newQueryString
        }, 
        undefined, { shallow: true }
    )
}

export const getImage = (banner, websmall, graphichero) => {
    
    if(graphichero){
        return graphichero;
    }
    else if(websmall){
        return websmall;
    }else if(banner){
        return banner;
    }
    return null;
}

export const getGlossaryLanguage = (lang) => {
    switch (lang) {
        case 'en-gb':
            return 'en_gb';
        case 'zh-hans':
            return 'zh_hans'
        default:
            return lang;
    }
}

export const unixToDateString = (timestamp) => {
    if (!timestamp) {
      return;
    }
    
    const date = new Date(timestamp * 1000);
    
    const monthNames = [
      'January', 'February', 'March', 'April', 'May', 'June',
      'July', 'August', 'September', 'October', 'November', 'December'
    ];
    
    const month = monthNames[date.getMonth()];
    const day = String(date.getDate()).padStart(2, '0');
    const year = date.getFullYear();
  
    return `${month} ${day}, ${year}`;
};

export const unixToDate = (timestamp) => {
    if(!timestamp){
        return;
    }
    const date = new Date(timestamp * 1000);

    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const year = date.getFullYear();

    return `${day}/${month}/${year}`;
}

export const generateLocationImageSrc = () => {
    const randomImageNumber = Math.floor(Math.random() * 10) + 1;
    return `/dlr-website/Digital%20Realty%20Facilities/Interior%20Images/Interior${randomImageNumber}.jpg`;
};
  
export const renderCardImage = (item) => {        
    switch (true){
        case item?.type === "metro":                        
            return <Image layout="fill" alt="search-image" src={item?.name_1} />
        case item?.hero_image !== undefined:
            return <Image layout="fill" alt="search-image" src={item?.hero_image} />
            //name_1 reffers to hero_image
        case item?.thumbnail_image !== undefined:
            return <Image layout="fill" alt="search-image" src={item?.thumbnail_image} />
        case item?.banner_image !== undefined:
            return <Image layout="fill" alt="search-image" src={item?.banner_image} />        
        case item?.type === "location":                        
            const randomImageNumber = Math.floor(Math.random() * 10) + 1;
            return <Image key={`randomImage${randomImageNumber}`} loading="lazy" layout="fill" alt="search-image" src={generateLocationImageSrc()} />;            
        default:
            return <Image layout="fill" alt="search-image" src={'/images/placeholder.png'} />
    }
}

export const formatIdStrings = (inputString) => {
    if(!inputString){
        return '';
    }
    return inputString.replace(/[^a-zA-Z0-9\s]/g, '').replace(/\s+/g, '-').toLowerCase();
}

export const formatCountryIdString = (inputString) => {
    if(!inputString) return;

    return inputString
        .replace(/[^a-zA-Z0-9\s()]/g, '')  
        .replace(/\(([^)]{0,2})\)/g, '$1')
        .replace(/\s+/g, '-')
        .toLowerCase();
}

export const handleGoTo = (url, router, lang = 'en') => {
    if (typeof window !== "undefined" && url !== undefined) {
        let output = url;
        if(lang !== 'en'){
            output = url.replace(/^\/[a-z-]+\//, "/");        
        }         
        router.push(output);
    }
}

export const getApplyAndCancelTextTranslations = (lang) => {

    if(lang == 'en-gb'){
        lang = 'uk';
    }else if(lang == 'zh-hans'){
        lang = 'cn';
    }

    let translations = {
        en: {
            apply: 'Apply Filter',
            cancel: 'Cancel'
        },
        de: {
            apply: 'Filter anwenden',
            cancel: 'Abbrechen'
        },
        nl: {
            apply: 'Filters toepassen',
            cancel: 'Annuleren'
        },
        uk: {
            apply: 'Apply Filter',
            cancel: 'Cancel'
        },
        ja: {
            apply: 'フィルターを適用する',
            cancel: 'キャンセル'
        },
        asia: {
            apply: 'Apply Filter',
            cancel: 'Cancel'
        },
        ko: {
            apply: '필터 적용',
            cancel: '취소'
        },
        cn: {
            apply: '应用过滤器',
            cancel: '取消'
        },
        es: {
            apply: 'Aplicar filtro',
            cancel: 'Cancelar'
        },
        fr: {
            apply: 'Appliquer le filtre',
            cancel: 'Annuler'
        },
        at: {
            apply: 'Filter anwenden',
            cancel: 'Abbrechen'
        }, 
        ch: {
            apply: 'Filter anwenden',
            cancel: 'Abbrechen'
        },     
    }

    if(!lang){
        return translations['en']
    }

    return translations[lang];

}

export const modifyLinks = (content) => {
    if(!content){
        return;
    }
    
    const linkRegex = /<a\s+(?:[^>]*?\s+)?href="([^"]*)"/g;
    const modifiedContent = content.replace(linkRegex, (match, href) => {
      const linkTarget = href.startsWith('/') ? '_self' : '_blank';
      const linkRel = href.startsWith('/') ? '' : 'noopener noreferrer';

      return `<a href="${href}" target="${linkTarget}" rel="${linkRel}"`;
    });

    return modifiedContent;
};

export const sortByTypeAndLocation = (results) => {
    const metroResults = [];
    const nonResourceResults = [];
    const otherResults = [];
    const locationResults = [];
    const regionResults = [];
    const facilityResults = [];

    results.forEach((result) => {
        if (result.type === 'metro') {
            metroResults.push(result);
        } else if (result.type == 'individual_product_template' || result.type == 'content_template' || result.type == 'info_template' || result.type == 'freestyle_template') {
            nonResourceResults.push(result);
        } else if (result.type === 'location') {
            facilityResults.push(result);
        } else if (result.type === 'region') {
            regionResults.push(result);
        } else if (results.type === 'global_data_centre_locations') {
            locationResults.push(result);
        } else {
            otherResults.push(result);
        }
    });

    metroResults.sort((a, b) => a.title.localeCompare(b.title));
    locationResults.sort((a, b) => a.title.localeCompare(b.title));
    facilityResults.sort((a, b) => a.title.localeCompare(b.title));
    regionResults.sort((a,b) => a.title.localeCompare(b.title));
    nonResourceResults.sort((a,b) => a.title.localeCompare(b.title));
    otherResults.sort((a, b) => new Date(b.created) - new Date(a.created));

    return [...locationResults, ...regionResults, ...metroResults, ...facilityResults,...nonResourceResults, ...otherResults, ];
};

export const containsOnlyNumbers = (str) => {
    if(!str) return;
    
    return /^\d+$/.test(str);
}

export const createFiltersFromRedirect = (filter, categories) => {
    let filters = [];

    const filterNames = Object.values(filter).flat().map(f => f.toLowerCase());

    for (let item of categories) {
        const itemName = item?.name?.toLowerCase();
    
        if (filterNames.includes(itemName) && !filters.some(f => f.name.toLowerCase() === itemName)) {
            filters.push(item);
        }
    }

    return filters;
}

export const formatFilters = (availableFilters) => {
    if(!availableFilters){
        return;
    }

    let filteredItems = [];

    for (let filter of availableFilters) {
        let itemName = filter['name'].substring(0, filter['name'].indexOf('('));
        let isDuplicate = filteredItems.some(item => item['name'] === itemName);

        if(itemName == '') continue;
    
        if (!isDuplicate) {
            let item = {
                name: itemName,
                tid: filter['id']
            };
            filteredItems.push(item);
        }
    }
    
    return filteredItems;
}

export const splitTetheredName = (data) => {
    return data.map(item => {
        const [baseName, tag] = item.name.split(/\(([^)]+)\)/).filter(Boolean);
        return {
            baseName,
            tag,
            tid: item?.tid
        };
    });
};

export const replaceShortCodes = (string, data) => {
    const regex = /\[\[(.*?)\]\]/g;

    if(!string){
        return;
    }

    if(!data){
        return string;
    }

    return string.replace(regex, (match, fieldName) => {
        const value = data[fieldName]?.[0]?.value;
        return value !== undefined ? value : match;
    });
};

export const translateStringUsingGlossary = (item, glossary, lang) => {
    if(!glossary){
        return;
    }

    const glossaryItem = glossary?.find(entry => entry?.['en'] === item);
    return glossaryItem ? glossaryItem?.[lang] : item;
}

export const handleGoogleTagManager = (indexName, cookieData) => {
    const dataLayerEvent = {
        event: "algolia_search",
        user_id: cookieData.userId,
        index: indexName,
        object_ids: cookieData.data,
    };

    if (window.dataLayer) {
        window.dataLayer.push(dataLayerEvent);
    } else {
        window.dataLayer = [dataLayerEvent];
    }
};

const setCookie = (name, value, days) => {
    let expires = "";
    if (days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "") + expires + "; path=/";
};

const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');

    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }

    return null;
};

const generateUniqueId = () => {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
    });
};

const getCookieData = (objectId) => {
    // const key = 'userId';
    const key = 'userIdGTM';
    let cookieData = getCookie(key);
    let sessionData;

    if (!cookieData) {
        sessionData = { id: generateUniqueId(), data: objectId ? [objectId] : [] };
        setCookie(key, JSON.stringify(sessionData), 30);
    } else {
        sessionData = JSON.parse(cookieData);
    }

    return sessionData;
};

export const setCookieUser = () => {
    getCookieData();
};

export const addUserDataToCookies = (objectId, indexName) => {
    const cookieData = getCookieData(objectId);

    if (!cookieData.data.includes(objectId)) {
        cookieData.data.push(objectId);
        // setCookie('userId', JSON.stringify(cookieData), 7);
        setCookie('userIdGTM', JSON.stringify(cookieData), 30);
    }

    handleGoogleTagManager(indexName, cookieData);
};