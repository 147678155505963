import Image from "next/image";
import {useRouter} from "next/router";
import {useState} from "react";

import {containsOnlyNumbers, isLinkAbsolute} from "../../util/DataUtil";
import {Constants, getBackendUrl} from "../../util/Constants";
import { useSelector } from "react-redux";

export const Breadcrumb = ({data , urlAlias, lang, hideHeader, contentType}) => {
    if(hideHeader == 1){
        return '';
    }

    const [breadcrumbsOpen, setBreadcrumbsOpen] = useState(false);
    const router = useRouter();
    const host = getBackendUrl(useSelector((state) => state.host.host));

    let filteredItemNames = [];
    let itemNames = urlAlias.split('/');

    itemNames.forEach(item => {
        filteredItemNames.push(item.replace(/-/g, ' '))
    })

    let useTitle = typeof data?.field_use_title_in_breadcrumb !== 'undefined' && data?.field_use_title_in_breadcrumb[0]?.value === '1';
    let items = [];

    const generateUrlBasedOnIndex = (index) => {

        let url = '';
        for (let i = 0; i < filteredItemNames.length; i++){

            if (i > index){
                break
            }
            url += '/' + filteredItemNames[i]

        }

        return url.replaceAll(" ","-");
    }

    if (filteredItemNames.length > 2){
        for (let i = (breadcrumbsOpen ? 0 : filteredItemNames.length - 2); i < filteredItemNames.length; i++){
            items.push({
                name : filteredItemNames[i],
                url : generateUrlBasedOnIndex(i)
            })
        }
    }else {
        filteredItemNames.forEach((item, index) => {
            items.push({
                name : item,
                url : generateUrlBasedOnIndex(index)
            })
        })
    }

    if (useTitle){
        items.pop();
        items.push({
            name : data.title,
            url : generateUrlBasedOnIndex(filteredItemNames.length + 2),
            noCapitalize : true
        })
    }

    // const renderItems = () => {
    //     return items.map((item, index) => {

    //         if(contentType == 'press_release' && containsOnlyNumbers(item.name)){
    //             return;
    //         }

    //         return <div className={'item'} key={index}>
    //             <span className={item.name.toLowerCase() === 'emea' ? 'span-breadcrumb-link capitalize' : 'span-breadcrumb-link'} onClick={ ()=> {
    //                 fetch(host.remoteServer + "existandpublished?alias=" + item.url + '&langcode=' + lang)
    //                     .then((response) => response.json())
    //                     .then((data) => {
    //                         if (data.exist === '0' || data.published === '0'){
    //                             if (typeof window !== "undefined") {
    //                                 localStorage.setItem('name', item.name)

    //                                 if(window.location.pathname !== '/resources'){
    //                                     // router.push('/resources');
    //                                     router.push({
    //                                         pathname: '/resources',
    //                                         query: { type: item.name },
    //                                     });
    //                                 }
    //                             }

    //                             return
    //                         }

    //                         if (isLinkAbsolute(item.url)){
    //                             window.open(item.url, '_blank');
    //                         }else {
    //                             router.push(item.url)
    //                         }
    //                     });
    //             }}
    //                   style={{
    //                       textTransform : item.noCapitalize ? 'none' : 'capitalize'
    //                   }}
    //             >{item.name}</span>
    //             <div className="arrow">
    //                 <Image layout="fill" alt="breadcrumb-arrow" src={'/images/breadcrumb-arrow-right.png'} />
    //             </div>
    //         </div>
    //     })
    // }

    const getUrl = (item) => {
        if (!item) return;
    
        const { url, name } = item;
        const pattern = /\/resources\/[^/]+\//;
    
        if (pattern.test(url)) {
            return url;
        } 
        
        const regex = /^(\/resources)\/[^/]+\/?$/i;
        const match = url.match(regex);
    
        if (match && name !== 'resources') {
            return `${match[1]}?type=${name}`;
        }
    
        return url;
    };

    const renderItems = () => {
        return items.map((item, index) => {
            if(contentType == 'press_release' && containsOnlyNumbers(item.name)){
                return;
            }

            return (
                <a href={getUrl(item)} target={isLinkAbsolute(item.url) ? '_blank' : ''} className={'item'} key={index}>
                    <span className={item.name.toLowerCase() === 'emea' ? 'span-breadcrumb-link capitalize' : 'span-breadcrumb-link'} style={{textTransform : item.noCapitalize ? 'none' : 'capitalize'}}>
                        {/* {item.name} */}
                        {renderName(item)}
                    </span>
                    <div className="arrow">
                        <Image layout="fill" alt="breadcrumb-arrow" src={'/images/breadcrumb-arrow-right.png'} />
                    </div>
                </a>
            )
        })
    }

    const renderName = (item) => {
        if (!item || !item?.name) {
            return;
        }
    
        return item?.name.includes('platform digital') ? "PlatformDIGITAL®" : item?.name;
    }    

    return <div className={'breadcrumb ' + data.content_type}>
        <div className={'container'}>
            {/* <a href="/home" className={'div-breadcrumb-link'}> */}
            <a href="/" className={'div-breadcrumb-link'}>
                <span>Home</span>
                <div className="arrow">
                    <Image layout="fill" alt="breadcrumb-arrow" src={'/images/breadcrumb-arrow-right.png'} />
                </div>
            </a>
            {
                (filteredItemNames.length > 2) && (!breadcrumbsOpen) &&
                <div>
                    <span onClick={ ()=> {
                        setBreadcrumbsOpen(true)
                    }}>...</span>

                    <div className="arrow">
                    <Image layout="fill" alt="breadcrumb-arrow" src={'/images/breadcrumb-arrow-right.png'} />
                    </div>
                </div>
            }
            {renderItems()}
        </div>
    </div>
}
