import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { getEditorColor, getEditorColorForIcon, renderColor, renderStatsBg } from "../../util/ColorUtil";
import { getEditorColors, getHoverColorLocation, isItUndefined, modifyLinks } from "../../util/DataUtil";
import { getObjectValueByMachineName } from "../../util/ObjectUtil";
import CtaBarButton from "../base/cta-bar-button";
import FacilityModal from "../modal/FacilityModal";
import { cleanHTML } from "../utils/htmlUtils";
import { getMetricSystemForLanguage } from "../../util/LocationsUtil";

const TemplateStatsRight = (props) => {
    const dataSet = { ...props?.facilityData, ...props?.metroData, ...props?.locationData, ...props?.regionData, ...props?.global };
    const isBlockWithCta = props?.children?.field_cta_bar_button_selection[0]?.cta_bar_button?.field_cta_bar_button_text_1[0]?.value ? true : false;
    const statsSize = props?.children.field_stats?.stats_coloured_fields_type?.length;
    const iconColor = getEditorColor(isItUndefined(props?.children?.field_long_formatted));
    const ediatbleIcon = getEditorColor(isItUndefined(props?.children?.field_long_formatted));
    const isDescriptionAndNotHeadline = !props?.children?.field_long_formatted[0]?.value && props?.children?.field_description[0]?.value ? true : false;
    const emphasizedTextColor = getEditorColors(isItUndefined(props?.children?.field_long_formatted), isItUndefined(props?.children?.field_description)).filter(x => x)[0];
    const [marketoTrigger, setMarketoTrigger] = useState(false);        
    const metricSystem = getMetricSystemForLanguage(props.lang);

    const transformValueFt2ToM2 = (value, dataSet) => {
      if(metricSystem == 'Metric'){
          if (value.includes('field_total_facility_ft2')) {
            return value.replace('[[field_total_facility_ft2]]', '[[field_total_facility_m2]]');
        }
      }       
      return value;
  };

  const transformValueM2ToFt2 = (value, dataSet) => {
    if(metricSystem == 'Metric'){
      if (value.includes('field_total_facility_m2')) {
        return value.replace('[[field_total_facility_m2]]', '[[field_total_facility_ft2]]');
      }   
    }    
    return value;
};
  

    const renderStats = (data) => {
        if (!data || data.length === 0) {
            return;
        }

        const filteredData = data.filter((item) => {
            const descriptionValue = item?.field_description_formatted[0]?.value;
            const targetString = "field_utility_power_capacity";

            // Check if the description includes the target string
            return descriptionValue && !descriptionValue.includes(targetString);
        });

        return filteredData.map((item, index) => {
            const descriptionValue = transformValueFt2ToM2(
                item?.field_description_formatted[0]?.value,
                dataSet
            );            
            const valueValue = transformValueM2ToFt2(
                item?.field_value_formatted[0]?.value,
                dataSet
            );            

            return (
                <div className="stats-list" key={`stats-list-${index}`}>
                    <span
                        className={'stats-value'}
                        dangerouslySetInnerHTML={{
                            __html: cleanHTML(
                                modifyLinks(
                                    abbreviateStringNumber(
                                        getObjectValueByMachineName(descriptionValue, dataSet, '', props?.contentType),
                                        props?.contentType
                                    )
                                )
                            ),
                        }}
                    />
                    <span
                        className={'stats-description'}
                        dangerouslySetInnerHTML={{
                            __html: modifyLinks(
                                abbreviateStringNumber(
                                    getObjectValueByMachineName(valueValue, dataSet, '', props?.contentType),
                                    props?.contentType
                                )
                            ),
                        }}
                    />
                </div>
            );
        });
    };

    const abbreviateStringNumber = (inputString, blockType) => {
        if (!inputString) return;

        const numberMatch = inputString.match(/\d+/);

        if (blockType == 'facility') {
            if (numberMatch) {
                if (numberMatch[0]?.startsWith('0')) {
                    return inputString;
                }
                const number = parseInt(numberMatch[0], 10);
                const abbreviatedNumber = abbreviateNumber(number, 1000);

                const outputString = inputString.replace(numberMatch[0], abbreviatedNumber);

                return outputString;
            }
        }

        return inputString;
    }

    const abbreviateNumber = (number, divisor) => {
        const abbreviations = ['', 'k', 'M', 'B', 't'];
        let abbreviationIndex = 0;
        while (number >= divisor && abbreviationIndex < abbreviations.length - 1) {
            number /= divisor;
            abbreviationIndex++;
        }
        const abbreviatedNumber = (Math.round(number * 1000) / 1000).toFixed(1).replace(/\.0$/, '');

        return abbreviatedNumber + abbreviations[abbreviationIndex];
    }

    const renderBullets = (data) => {
        if (!data || data.length == 0) {
            return;
        }

        return data.map((item, key) => {
            return <div className="bullet-items" key={`bullet-item-${key}`}>
                {props.children?.field_font_awesome_icon[0]?.icon_name ? <FontAwesomeIcon className={iconColor ? iconColor : ''} path="fill" color={iconColor ? iconColor : ''} fill={iconColor ? iconColor : ''}
                    icon={[props?.children?.field_font_awesome_icon[0]?.style, props?.children?.field_font_awesome_icon[0]?.icon_name]}
                    style={{ fill: iconColor ? iconColor : '' }}
                /> : <img style={{ filter: getHoverColorLocation(ediatbleIcon) }} className="checkbox" alt="checkbox" src="/images/check-box.svg" />}
                <span className="bullet-item">{getObjectValueByMachineName(item?.value, dataSet)}</span>
            </div>
        })
    }

    return (
        <>
            <div className={`template-stats ${renderStatsBg(props?.children?.field_text_color[0]?.value, props?.regionColor)} ${statsSize == 1 ? 'single-stat' : ''} ${isBlockWithCta ? 'with-cta' : ''} ${isDescriptionAndNotHeadline ? 'without-headline' : ''}`}>
                <div className="inner-container">
                    <div className={"template-stats-container"}>
                        <div className={`template-title-div`}>
                            {props?.children?.field_long_formatted[0]?.value ? <span dangerouslySetInnerHTML={{ __html: cleanHTML(modifyLinks(props?.children?.field_long_formatted[0]?.value)) }} className={`template-stats-title ${emphasizedTextColor ? emphasizedTextColor : ''}`}></span> : <></>}
                            {props?.children?.field_description[0]?.value ? <span dangerouslySetInnerHTML={{ __html: cleanHTML(modifyLinks(props?.children?.field_description[0]?.value)) }} className={`template-stats-description ${emphasizedTextColor ? emphasizedTextColor : ''}`} /> : <></>}
                            {props?.children?.field_cta_bar_button_selection[0]?.cta_bar_button?.field_cta_bar_button_text_1[0]?.value ? <CtaBarButton setMarketoTrigger={setMarketoTrigger} blockTypeClass={'stats-right'} data={props?.children?.field_cta_bar_button_selection[0]?.cta_bar_button} bgColor={renderStatsBg(props?.children?.field_text_color[0]?.value, props?.regionColor)} /> : <></>}
                            {props?.children?.field_bullet_item_text?.length > 0 ? <div className="bullets">{renderBullets(props?.children?.field_bullet_item_text)}</div> : <></>}
                        </div>
                        <div className={`stats ${statsSize == 1 ? 'single-stat' : ''}`}>
                            {statsSize >= 2 ? renderStats(props?.children?.field_stats?.stats_coloured_fields_type) :
                                props?.children?.field_stats?.stats_coloured_fields_type?.length > 0 ? <div className="overlay">
                                    {renderStats(props?.children?.field_stats?.stats_coloured_fields_type)}
                                    <img className="overlay" src="/images/why-super-overlay.png"></img>
                                </div> : <></>}
                        </div>
                    </div>
                </div>
            </div>
            {marketoTrigger && <FacilityModal lang={props?.lang} global={props?.global} setTrigger={setMarketoTrigger} trigger={marketoTrigger}>{props?.children?.field_cta_bar_button_selection[0]?.cta_bar_button?.field_marketo_blocks[0]?.marketo_form_facility_block_type}</FacilityModal>}
        </>
    )
}

export default TemplateStatsRight;
