import Link from "next/link";
import { useEffect, useState } from "react";
import { CtaBarButtonType } from "../../constants/CtaBarButton";
import { isLinkAbsolute } from "../../util/DataUtil";

const CtaBarButton = ({data, setMarketoTrigger, bgColor, hasCtaArrow, fontSize, click, blockTypeClass}) => {
    const [anchortToMarketo, setAnchorToMarket] = useState(0);
    
    if(click && data?.field_cta_bar_button_type[0]?.value == CtaBarButtonType.LINK_TO_MARKETO){
        click(data?.field_marketo_blocks[0]?.marketo_form_facility_block_type);
    }
    useEffect(() => {
        if(anchortToMarketo > 0){
            let doc = document.getElementsByClassName('facility-marketo');
            if(doc[0]?.length == 0){
                return
            }
            const y = doc[0]?.offsetTop;
            if(doc[0]){
                window.scrollTo({top:y-210, behavior: "smooth"});
            }
        }
    },[anchortToMarketo])

    const renderHtml = (data) => {
        if(!data  || data.length == 0) return '';

        if(data?.field_cta_bar_button_type[0]?.value == CtaBarButtonType.URL_TO_ANOTHER_PAGE){
            return <div className={`cta-container`}><Link href={data?.field_cta_bar_link[0]?.value ? data?.field_cta_bar_link[0]?.value : ''} passHref legacyBehavior>
            <a target={isLinkAbsolute(data?.field_cta_bar_link[0]?.value) ? '_blank' : '_self'} className={`a-${blockTypeClass}-button cta-link ${data?.field_cta_styles[0]?.value == '1' ? 'secondary' : ''} ${bgColor ? bgColor.toLowerCase() : ''} ${hasCtaArrow ? 'arrow': ''}`}>{data?.field_cta_bar_button_text_1[0]?.value}</a>
        </Link></div>
        }else if(data?.field_cta_bar_button_type[0]?.value == CtaBarButtonType.LINK_TO_MARKETO){
            return <div onClick={() => setMarketoTrigger(true)} className="cta-container"><a style={{fontSize: `${fontSize ? fontSize : 16}px`}} className={`a-${blockTypeClass}-button cta-link ${data?.field_cta_styles[0]?.value == '1' ? 'secondary' : ''} ${bgColor ? bgColor.toLowerCase() : ''} ${hasCtaArrow ? 'arrow': ''}`}>{data?.field_cta_bar_button_text_1[0]?.value}</a></div>
        }else if(data?.field_cta_bar_button_type[0]?.value == CtaBarButtonType.ANCHOR_TO_MARKETO_FORM){
            return <div onClick={() => setAnchorToMarket(anchortToMarketo + 1)} className="cta-container"><a style={{fontSize: `${fontSize ? fontSize : 16}px`}} className={`a-${blockTypeClass}-button cta-link ${data?.field_cta_styles[0]?.value == '1' ? 'secondary' : ''} ${bgColor ? bgColor.toLowerCase() : ''} ${hasCtaArrow ? 'arrow': ''}`}>{data?.field_cta_bar_button_text_1[0]?.value}</a></div>
        }else if(data?.field_cta_bar_button_type[0]?.value == CtaBarButtonType.LINK_OR_UPLOAD_ASSET){
            return <div className={`cta-container`}><Link href={data?.field_marketo_file_url[0]?.value ? data?.field_marketo_file_url[0]?.value : ''} passHref legacyBehavior>
            <a style={{fontSize: `${fontSize ? fontSize : 16}px`}} target={isLinkAbsolute(data.field_marketo_file_url[0]?.value) ? '_blank' : '_self'} className={`a-${blockTypeClass}-button cta-link ${data?.field_cta_styles[0]?.value == '1' ? 'secondary' : ''} ${bgColor ? bgColor.toLowerCase() : ''} ${hasCtaArrow ? 'arrow': ''}`}>{data?.field_cta_bar_button_text_1[0]?.value}</a>
        </Link></div>
        }
    }
    return renderHtml(data)
}

export default CtaBarButton;
