import React, { useState, useEffect } from "react";
import ResourceFilter from "../components/resources/resource-filter";
import {
    applyTruncationForScreen,
    assemble,
    correctUrlFormatForRouting,
    correctDomainForLanguage,
    fetchData,
    fetchDataApiV2,
    tempRender,
    isLinkAbsolute, marketoOptions as options, createFilterData, getAlgoliaDatasetValue,
    getAlgoliaIndex,
    renderCardImage,
    removeQueryParam,    
} from "../util/DataUtil";
import {getLanguage, useWindowSize} from "../util/PageUtil";
import {useRouter} from "next/router";
import ResourceTitle from "../components/resources/resource-title";
import { Constants } from "../util/Constants";
import Image from "next/image";


export function GlobalSearchPage({data,width, globalMetrics}) {        
    const router = useRouter();
    const windowSize = useWindowSize();
    const [query, setQuery] = useState(router.query.search ? router.query.search : '');
    const [page, setPage] = useState(0);
    const [facetFilters, setFacetFilters] = useState([]);
    const [languageFilter, setLanguageFilter] = useState([]);
    const [results, setResults] = useState(false);
    const [applyFilter, setApplyFilter] = useState(false);
    const [algoliaData, setAlgoliaData] = useState();
    const [filterProps, setFilterProps] = useState([]);
    const [showMore, setShowMore] = useState([]);
    const [topSearchItems, setTopSearchItems] = useState([]);
    const [hostname, setHostname] = useState('');
    const cardId = router.query.cardId;


    useEffect(() => {
        setAlgoliaData(fetchAlgoliaData())
        setQuery(router.query.search ? router.query.search : '')
    }, [router.query.search]);

    let hitsPerPage = 16;
    let lng = typeof window !== 'undefined' ? getLanguage(window.location.hostname) : "";
    let facetFiltersArray = [];

    useEffect(() => {
        getMostPopularSearchTerms();
    }, [])

    useEffect(() => {
        if(!lng){
            return;
        }

        fetchAlgoliaData();
    }, [applyFilter, query]);

    useEffect(() => {
        if(!lng){
            return;
        }

        if(page > 0){
            fetchAlgoliaPagination();
        }
    }, [page]);

    useEffect(() => {
        if (typeof window !== "undefined") {
            let localStorageFilter = localStorage.getItem('name');

            if(localStorageFilter){
                // triggerFilter([{name: localStorageFilter}]);
                triggerFilterFromRedirect([{name: localStorageFilter}]);
            }

            localStorage.removeItem('name');
        }
    }, []);

    useEffect(() => {
        if(typeof window !== 'undefined'){
            setHostname(window.location.hostname);
        }
    }, []);

    let lngArray = [];

    const fetchAlgoliaData = () => {
        // setResults((query.length || facetFilters.length || languageFilter.length) !== 0);

        // let url = createFilterData(lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, lng, getAlgoliaDatasetValue(0));
        // let url = createFilterData(lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, lng, 'globalprod');
        let url = createFilterData(lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, lng, 'global' + getAlgoliaIndex());

        const fetchAlgolia = async () => {
            await fetch(url, options)
                .then((response) => response.json())
                .then(
                    newResponse => {
                        setShowMore(newResponse.hits);
                        setAlgoliaData(newResponse);
                        setResults(true);
                    });
        }

        fetchAlgolia()
    }

    const fetchAlgoliaPagination = () => {

        // let url = createFilterData(lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, lng, 'globalprod');
        let url = createFilterData(lngArray, facetFiltersArray, facetFilters, query, hitsPerPage, page, lng, 'global' + getAlgoliaIndex());

        const fetchAlgolia = async () => {
            await fetch(url, options)
                .then((response) => response.json())
                .then(
                    newResponse => {
                        setShowMore(previousResponse => [...previousResponse, ...newResponse.hits]);
                        setAlgoliaData(newResponse);
                    });
        }

        fetchAlgolia()
    }

    const fetchAlgoliaMostPopular = (queryParam) => {
        let lang = JSON.stringify("search_api_language:" + lng);
        // let url = 'https://SWULV5ONQ5-dsn.algolia.net/1/indexes/drupal?query=' + queryParam + '&facetFilters=[' + lang + ']&hitsPerPage=3&page=0';
        // let url = 'https://O2VJB2KMPB-dsn.algolia.net/1/indexes/drupalprod?query=' + queryParam + '&facetFilters=[' + lang + ']&hitsPerPage=3&page=0';
        let url = Constants.algoliaProduction + 'drupalprod?query=' + queryParam + '&facetFilters=[' + lang + ',["status:true"]]&hitsPerPage=3&page=0';

        const fetchData = async () => {
            await fetch(url, options)
                .then((response) => response.json())
                .then(
                    newResponse => {
                        setTopSearchItems(previousResponse => [...previousResponse, ...newResponse.hits])
                    });
        }
        fetchData()
    }

    const handleSearch = (search) => {
        setQuery(search.length !== 0 ? [search] : []);
        setFilterProps([]);
        setFacetFilters([]);
        setPage(0);
        setApplyFilter(false);
    }

    const triggerFilter = (filters) => {
        setFilterProps(filters);

        let searchFilters = [];
        for(let filter of filters){
            searchFilters.push(filter?.field_source_translation);
        }

        setFacetFilters(searchFilters);
        setPage(0);
        setApplyFilter(false);
    }

    const handleClick = (event, urlAlias) => {
        event.stopPropagation();

        if(urlAlias?.url?.length !== 0){
            if (isLinkAbsolute(correctDomainForLanguage(urlAlias?.language, hostname) + urlAlias?.url)){
                window.open(correctDomainForLanguage(urlAlias?.language, hostname) + urlAlias?.url, '_blank');
            }else{
                router.push(correctDomainForLanguage(urlAlias?.language, hostname) + urlAlias?.url);
            }
        }
    }

    const triggerFilterFromRedirect = (filter) => {
        for(let item of data?.categories){
            if(item.name.toUpperCase() === filter[0].name.toUpperCase()){
                triggerFilter([item]);
            }
        }
        setApplyFilter(true);
        // setQuery([]);
    }

    const handleChipClick = (event, filter) => {
        event.stopPropagation();
        for(let item of data?.categories){
            if(item.name === filter[0].name){
                triggerFilter([item]);
            }
        }
        setApplyFilter(true);
        setQuery([]);
    }

    const renderCategories = (categories) => {
        if(typeof categories == 'object'){
            return categories.map((item, index)=>{
                return <div onClick={(event) => handleChipClick(event, [{name : item}])}>{item}</div>
            });
        }else {
            return categories;
        }
    }

    const clearAll = () => {
        setFilterProps([])
        triggerFilter([])
        setLanguageFilter([])
        setQuery([])
        removeQueryParam(router)

        // fetchAlgoliaData()
    }

    const getMostPopularSearchTerms = () => {
        // fetch('https://analytics.algolia.com/2/searches?index=drupal', options)
        fetch('https://analytics.algolia.com/2/searches?index=drupalprod', options)
            .then((response) => response.json())
            .then((actualData) => {
                getTopSearchesFromAlgolia(actualData)
            });
    }

    const getTopSearchesFromAlgolia = (searchTerms) => {
        if(!searchTerms){
            return;
        }

        for(let item of searchTerms?.searches){
            if(item.nbHits !== 0){
                fetchAlgoliaMostPopular(item?.search)
            }
        }
    }

    const handleScroll = () => {
        if(typeof  window !== 'undefined'){
            window.scrollTo({top: 0, behavior: 'smooth'});
        }
    }

    const renderSearchCards = (data, length = -1) => {
        let result = [];
        if(cardId){
            length = 1
        }else{
            length = -1
        }
        if(!data){
            return;
        }
        if(length !== -1){
            data = [...new Set(data)].slice(0, length);
        }                

        const locationResults = data.filter(item => item?.type === 'global_data_centre_locations');
        const regionResults = data.filter(item => item?.type === 'region');
        const metroResults = data.filter(item => item?.type === 'metro');
        const facilityResults = data.filter(item => item?.type === 'location');
        const nonResourceResults = data.filter(item => (item?.type == 'individual_product_template' || item?.type == 'content_template' || item?.type == 'info_template' || item?.type == 'freestyle_template'));
        const otherResults = data.filter(item => {
            return (
                item?.type !== 'global_data_centre_locations' &&
                item?.type !== 'region' &&
                item?.type !== 'metro' &&
                item?.type !== 'facility' &&
                !(item?.type === 'individual_product_template' || item?.type === 'content_template' || item?.type === 'info_template' || item?.type === 'freestyle_template')
            );
        });
        const uniqueCombinedData = new Set([...locationResults, ...regionResults, ...metroResults, ...facilityResults, ...nonResourceResults, ...otherResults]);
        const combinedDataArray = [...uniqueCombinedData];
        
        let index = 0

        for(let item of combinedDataArray){            
            result.push(
                <div key={index} className={'search-card'} onClick={(event) => handleClick(event, item.url ? correctUrlFormatForRouting(item.url) : '')}>
                    <div className={'img-container'}>
                        {/* <img src={item.thumbnail_image ? item.thumbnail_image : '/images/placeholder.png'} alt={'alt'}/> */}
                        { renderCardImage(item) }
                    </div>
                    <div className={'card-text-container'}>
                        {/*<div className={'card-text'}><p>{item.content_type ? item.content_type : 'Interconnection'}, {item.field_time_to_read ? item.field_time_to_read : '3 min'}</p></div>*/}
                        {
                            item.content_type ? (
                                <div className={'card-text'}>                                    
                                    <p>{item.content_type === 'Case Studies' ? globalMetrics?.field_case_studies_label?.[0]?.value : item.content_type}</p>
                                </div>
                            ) : (item.type || item.field_page_type) ? (
                                <div className={'card-text'}>
                                {
                                    (() => {
                                    switch (item?.field_page_type) {
                                        case 'Content Hub':
                                        return <p>{globalMetrics?.field_content_hub_label?.[0]?.value || item?.field_page_type}</p>;
                                        case 'Content Sub':
                                        return <p>{globalMetrics?.field_content_sub_label?.[0]?.value || item?.field_page_type}</p>;
                                        case 'Partners':
                                        return <p>{globalMetrics?.field_partners_label?.[0]?.value || item?.field_page_type}</p>;
                                        case 'Insights':
                                        return <p>{globalMetrics?.field_insights_label?.[0]?.value || item?.field_page_type}</p>;
                                        case 'Solutions':
                                        case 'Solutions With Card Stepper':
                                        return <p>{globalMetrics?.field_solutions_label?.[0]?.value || item?.field_page_type}</p>;
                                        // Add more cases as needed
                                        default:
                                        // For item?.type conditions
                                        switch (item?.type) {
                                            case 'location':
                                            return <p>{globalMetrics?.field_facility_label?.[0]?.value || item?.type}</p>;
                                            case 'global_data_centre_locations':
                                            return <p>{globalMetrics?.field_global_data_centre_locatio?.[0]?.value || item?.type}</p>;
                                            case 'region':
                                            return <p>{globalMetrics?.field_region_label?.[0]?.value || item?.type}</p>;
                                            case 'metro':
                                            return <p>{globalMetrics?.field_metro_label?.[0]?.value || item?.type}</p>;
                                            case 'individual_product_template':
                                            return <p>{globalMetrics?.field_products_label?.[0]?.value || item?.type}</p>;
                                            case 'info_template':
                                            return <p>{globalMetrics?.field_info_template_label?.[0]?.value || item?.type}</p>;
                                            case 'press_release':
                                            return <p>{globalMetrics?.field_press_release_label?.[0]?.value || item?.type}</p>;
                                            // Add more cases as needed
                                            default:
                                            return <p></p>; // Default rendering when none of the conditions are met
                                        }
                                    }
                                    })()
                                }
                                </div>
                            ) : (
                                <div className={'card-text'}>
                                    {/* Default rendering when none of the conditions are met */}
                                    <p></p>
                                </div>
                            )
                        }

                        <span className={'title'}>{item?.field_template_title ? applyTruncationForScreen(item?.field_template_title, 42, 55, 55 , 30) : applyTruncationForScreen(item?.title, 42, 60, 60, 30)}</span>
                    </div>
                    {/*<div className={'second-category tag-list'} onClick={(event) => handleChipClick(event, [{name : item.category_name ? item.category_name : 'Blog'}])}>{item.category_name ? renderCategories(item.category_name) : 'Blog'}</div>*/}
                    {
                        item?.name
                        ?
                        // <div className={'second-category tag-list'} onClick={(event) => handleChipClick(event, [{name : item.name ? item.name : ''}])}>{item.name ? item.name : ''}</div>
                        <div className={'second-category tag-list'} onClick={(event) => {
                            event.stopPropagation()
                            setQuery(item?.name ? item?.name : '')
                            handleScroll()
                        }}
                        >
                            {item?.name ? item?.name : ''}
                        </div>
                        :
                            item?.primary_source_language
                            ?
                            // <div className={'second-category tag-list'} onClick={(event) => handleChipClick(event, [{name : item.primary_source_language ? item.primary_source_language : ''}])}>{item.primary_source_language ? item.primary_source_language : ''}</div>
                            <div className={'second-category tag-list'} onClick={(event) => {
                                event.stopPropagation()
                                setQuery(item?.primary_source_language ? item?.primary_source_language : '')
                                handleScroll()
                            }}
                            >
                                {item?.primary_source_language ? item?.primary_source_language : ''}
                            </div>
                            :
                                typeof item?.system_source_language === "string"
                                ?
                                // <div className={'second-category tag-list'} onClick={(event) => handleChipClick(event, [{name : item.system_source_language ? item.system_source_language : ''}])}>{item.system_source_language ? item.system_source_language : ''}</div>
                                <div className={'second-category tag-list'} onClick={(event) => {
                                    event.stopPropagation()
                                    setQuery(item?.system_source_language ? item?.system_source_language : '')
                                    handleScroll()
                                }}
                                >
                                    {item?.system_source_language ? item?.system_source_language : ''}
                                </div>
                                :
                                    typeof item?.system_source_language === 'object'
                                    ?
                                    // <div className={'second-category tag-list'} onClick={(event) => handleChipClick(event, [{name : item.system_source_language ? item.system_source_language[0] : ''}])}>{item.system_source_language ? item.system_source_language[0] : ''}</div>
                                    <div className={'second-category tag-list'} onClick={(event) => {
                                        event.stopPropagation()
                                        setQuery(item?.system_source_language ? item?.system_source_language[0] : '')
                                        handleScroll()
                                    }}
                                    >
                                        {item?.system_source_language ? item?.system_source_language[0] : ''}
                                    </div>
                                    :
                                    ''
                    }
                </div>
            )
            index++
        }

        return result;
    }

    function renderSearchResults(data){        
        if(!data){
            return;
        }        
        if(data.length === 0){
            return (
                <div className={'no-result'}>
                    <h2>{globalMetrics?.field_no_results_text?.[0]?.value || 'No results were found. Please check the spelling, reset any filters and try again.'}</h2>
                    <div className={'clear-all'} onClick={() => { clearAll() }}>{globalMetrics?.field_clear_all_label?.[0]?.value || 'Clear all search terms.'}</div>
                    <div className={'most-popular'}>
                        <h2>{globalMetrics?.field_most_popular_text?.[0]?.value || 'Most popular'}</h2>
                        <div className={'search-results'}>{renderSearchCards(topSearchItems, 12)}</div>
                    </div>
                </div>
            );
        }

        return renderSearchCards(data);
    }

    return <div className={'resource'}>
        <ResourceTitle title={data?.field_resources_title[0]?.value} subtitle={data?.field_resources_subtitle[0]?.value} placeholder={'Search All Resources...'} />
        <div className={'global-search'}>
            <ResourceFilter search={data?.field_search_resources[0]?.value} title={data?.field_filter_resources[0]?.value}
                            filterProps={filterProps} triggerFilter={triggerFilter} data={assemble(data?.categories)}
                            onSearchChange={handleSearch} handleFilterApply={setApplyFilter} searchQuery={query}
                            setQuery={setQuery} languageFilter={languageFilter} setLanguageFilter={setLanguageFilter}
                            screenWidth={windowSize.width}
                            clearAll={clearAll} hideFilter={true} clearQuery={clearAll}
            />
        </div>
        {
            results
                ?
                <div className={'search-results-header inner-container'}>
                    <h2>{globalMetrics?.field_results_label?.[0]?.value || 'Results'}</h2>
                    <div className={'horizontal-line'} />
                    <div className={'search-results'}>{renderSearchResults(showMore, data)}</div>
                    {
                        (algoliaData?.page + 1 !== algoliaData?.nbPages && algoliaData?.nbHits !== 0)&&
                        <div className={'show-more'} onClick={() => setPage(page + 1)}>Show More</div>
                    }
                </div>
                :
                <></>
        }
    </div>
}

export async function categoriesProps(endpoint,lang) {
    return fetchData(endpoint,'template-categories?lang=' + lang);
}

export async function popularDownloadsProps(endpoint,lang) {
    const res = await fetch(endpoint.apiV2Url + 'popular-downloads?langcode=' + lang);
    // return fetchData('popular-downloads?lang+' + lang)
    return await res.json();
}

// export async function featuredProps(data) {
//     return fetchData('templates?featured=1&lang=' + data.langcode);
// }

export async function latestProps(endpoint,lang) {
    const res = await fetch(endpoint.apiV2Url + 'latest-insights?langcode=' + lang)
    return await res.json();
}







