import Image from "next/image";
import { useRouter } from "next/router";
import { useEffect, useState } from "react";
import { applyTruncationForScreen, findImageByKey, findImageByKeyWebLarge, findValueByKey, getResourceContentImageSrc, isItUndefined, isLinkAbsolute, removeNbsp, stripHtml } from "../../util/DataUtil";

const TempalteFeaturedContent = ({children}) => {

    const featured = children?.field_featured_posts;    
    const [titles, setTitles] = useState([]);
    const title = isItUndefined(children?.field_title_color);
    const router = useRouter();    
    
    const truncate = () => {
        let titles = [];

        featured.forEach((item, index) => {
            titles.push(
                applyTruncationForScreen(findValueByKey('template_title_block_type', item?.field_template_blocks)?.field_template_title ? findValueByKey('template_title_block_type', item?.field_template_blocks)?.field_template_title?.[0]?.value : removeNbsp(stripHtml(findValueByKey('hero_graphic_block_type', item?.field_template_blocks)?.field_formatted_title?.[0]?.value)), index === 0 ? 120 : 47, 50, 60)
            )
        })

        setTitles(titles);
    }

    useEffect(() => {
        truncate()
    }, [featured])

    const onChipClick = (event, category) => {
        if (typeof window !== "undefined") {
            // localStorage.setItem('name', category[0]['name'])

            router.push({
                pathname: '/resources',
                query: { category: category[0]['name'].toLowerCase() },
            });
        }
    }

    const renderFeatured = () => {
        return featured?.map((item, index) => {
            if (index === 0){
                return; 
            }
            return <div key={index} className={'item'} >
                {
                    (index === 1) &&
                    <div className={'div-resource-content-link img-container'} onClick={() => {
                        if (isLinkAbsolute(item['url-alias'])){
                            window.open(item['url-alias'], '_blank');
                        }else{
                            router.push(item['url-alias'])
                        }
                    }}>
                        <Image priority layout="fill" alt="featured" src={getResourceContentImageSrc(item)} />
                    </div>
                }
                <span className={'category'}>                                    
                {item && item?.field_content_type_templat && item?.field_content_type_templat[0]?.name && (
                    <>
                    {item?.field_content_type_templat[0]?.name}
                    {isItUndefined(item?.field_time_to_read) ? ',' : ''} {isItUndefined(item?.field_time_to_read)}
                    </>
                )}
                {/* {item && item?.field_page_type && item?.field_page_type[0]?.value && (
                    <>
                    {item?.field_page_type[0]?.value}
                    {isItUndefined(item.field_time_to_read) ? ',' : ''} {isItUndefined(item.field_time_to_read)}
                    </>
                )} */}
                </span>

                <span className={'title span-resource-content-link'}
                      onClick={() => {
                          if (isLinkAbsolute(item['url-alias'])){
                              window.open(item['url-alias'], '_blank');
                          }else{
                              router.push(item['url-alias'])
                          }
                      }}
                >{titles[index]}</span>
                {
                    // item.field_template_category?.length > 0 && item?.field_template_category[0]?.name &&
                    <span className={`second-category category-hover${!item?.field_template_category?.[0]?.name ? ' undefined-category' : ''}`} onClick={(event) => onChipClick(event, [{ name: item?.field_template_category?.[0]?.name || '' }])} >
                        {item?.field_template_category?.[0]?.name || ''}
                    </span>
                }
                <hr />
            </div>
        })
    }

    return <div className="template-featured-content">
        <div className="inner-container">
            <span className="page-sub" dangerouslySetInnerHTML={{__html: title}}/>
            <hr />
            <div className={'featured'}>
                <div className={'item-big'} >
                    <div onClick={() => {
                        if (isLinkAbsolute(featured[0]['url-alias'])){
                            window.open(featured[0]['url-alias'], '_blank');
                        }else{
                            router.push(featured[0]['url-alias'])
                        }
                    }} className={'img-container div-resource-content-link'}>
                        <Image layout="fill" alt="featured" src={getResourceContentImageSrc(featured[0])} />
                    </div>
                    {featured[0] && featured[0]?.field_content_type_templat && featured[0]?.field_content_type_templat[0]?.name && (
                    <span
                        // onClick={(event) => onChipClick(event, [{name : featured[0]?.field_template_category[0]?.name}])}
                        className={'category'}
                    >
                        {featured[0]?.field_content_type_templat[0]?.name}
                        {isItUndefined(featured[0]?.field_time_to_read) ? ',' : ''} {isItUndefined(featured[0]?.field_time_to_read)}
                    </span>
                    )}                    
                    {/* {featured[0] && featured[0]?.field_page_type && featured[0]?.field_page_type[0]?.value && (
                    <>
                        <span className="category">
                            {featured[0]?.field_page_type[0]?.value}
                            {isItUndefined(featured[0]?.field_time_to_read) ? ',' : ''} {isItUndefined(featured[0].field_time_to_read)}
                        </span>                    
                    </>
                    )}                     */}

                    <span onClick={() => {
                        if (isLinkAbsolute(featured[0]['url-alias'])){
                            window.open(featured[0]['url-alias'], '_blank');
                        }else{
                            router.push(featured[0]['url-alias'])
                        }
                    }} className={'title'}>{titles[0]}</span>
                    {/*<span className={'text'}>{truncateText(featured[0]?.template_text[0]?.field_template_text, 310)}</span>*/}
                    { (<span className={`second-category category-hover${!(featured && featured[0] && featured[0]?.field_template_category?.[0]?.name) ? ' undefined-category' : ''}`} onClick={(event) => onChipClick(event, [{ name: featured[0]?.field_template_category?.[0]?.name || '' }])} >
                      {(featured && featured[0] && featured[0]?.field_template_category?.[0]?.name) || ''}
                      </span>
                    )}
                    <hr className={'item-big-hr'} />
                </div>
                <div className={'small-items'}>{renderFeatured()}</div>
            </div>
        </div>
    </div>
}

export default TempalteFeaturedContent;
