export function getObject(theObject, key) {
    var result = null;
    if(theObject instanceof Array) {
        for(var i = 0; i < theObject.length; i++) {
            result = getObject(theObject[i], key);
            if (result) {
                break;
            }
        }
    }
    else
    {
        for(var prop in theObject) {
            if(prop === key) {
                return theObject.meta_tags;
            }
            if(theObject[prop] instanceof Object || theObject[prop] instanceof Array) {
                result = getObject(theObject[prop], key);
                if (result) {
                    break;
                }
            }
        }
    }
    return result;
}

const removeDoubleSquareBrackets =(inputString) => {
    const pattern = /\[\[(.*?)\]\]/g;
    const outputString = inputString.replace(pattern, '$1');
    return outputString;
}

const renderSubMatchs = (submatch, renderInNewLines = false) => {
    if(!submatch || submatch.length == 0) return '';

    else if(typeof submatch  == 'string' || typeof submatch == 'number') {
        return submatch;
    }

    return submatch?.map((e,index) => {
        if(renderInNewLines){
            return e?.value ? `<span>${e.value}</span>` : e
        }
        return e?.value ? index > 0 ? ` ${e.value}` : e.value : e
    })
}

// export const getObjectValueByMachineName = (string, data, renderInNewLines) => {
//     if(!string) return;
//     else if(!data) return string;

//     let matches = string.match(/\[\[(.*?)\]\]/g);

//     if(matches){

//         do {
//             if(matches){
//                 let submatch = removeDoubleSquareBrackets(matches[0]);

//                 let objectKey = submatch in data;
//                 if(!objectKey) return string;
        
//                 string = string.replace(`[[${submatch}]]`, data[submatch][0]?.value && data[submatch]?.length == 1 ? data[submatch][0].value : renderSubMatchs(data[submatch], renderInNewLines));
        
//                 matches = string.match(/\[\[(.*?)\]\]/g);
//             }
//         } while(matches);
//     }
    
//     return string;
// }

export const getObjectValueByMachineName = (string, data, renderInNewLines, contentType) => {
    if (!string) return;
    if (!data) return string;

    if(contentType === 'metro'){
        if (string.includes('service_providers_count')) {
            string = string.replace(']]', ']]+').replace('service_providers_count', 'field_customers');
        } else if (string.includes('aggregated_cloud_networks')) {
            string = string.replace(']]', ']]+').replace('aggregated_cloud_networks', 'field_providers');
        }
    }

    let matches = string.match(/\[\[(.*?)\]\]/g);

    if (matches) {
        do {
            if (matches) {
                let submatch = removeDoubleSquareBrackets(matches[0]);
                let objectKey = submatch in data;
                if (!objectKey) return string;

                if(data[submatch].length === 0){
                    string = '';
                }

                string = string.replace(`[[${submatch}]]`, data[submatch][0]?.value && data[submatch]?.length === 1 ? data[submatch][0].value : renderSubMatchs(data[submatch], renderInNewLines));

                matches = string.match(/\[\[(.*?)\]\]/g);
            }
        } while (matches);
    }

    return string;
};

export const getItemValueByMachineName = (string, data) => {
    if(!string) return;
    else if(!data) return string;

    let matches = string.match(/\[(.*?)\]/);
    if(matches){

        let submatch = matches[1].slice(1);

        let objectKey =  submatch in data;
        if(!objectKey) return;

        return data[submatch].length == 1 && data[submatch][0]?.value ? data[submatch][0].value : data[submatch];
    }

    return string;
}