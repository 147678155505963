let hostname = 'drupal-service:8080'; // uat

// hostname = 'dlr-backend.getdeveloper.net';
// process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = '0';

// process.env['NODE_TLS_REJECT_UNAUTHORIZED'] = 0;
// process.env.NODE_TLS_REJECT_UNAUTHORIZED = 0;
export const Constants = {
    baseUrl : `http://${hostname}/`,
    baseApiUrl : `http://${hostname}/api/`,
    apiV2Url: `http://${hostname}/apiv2-dlr/`,
    languageKey : `frontend`,
    cloudinaryImage : 'https://res.cloudinary.com/digitalrealty/image/upload/f_auto,q_auto,c_scale,dpr_auto,w_auto/',
    frontendUrl: `http://${hostname}/`,
    algoliaTest : `https://SWULV5ONQ5-dsn.algolia.net/1/indexes/`,
    algoliaProduction : `https://O2VJB2KMPB-dsn.algolia.net/1/indexes/`,
    headers : {
        method: 'GET',
        headers: {
            'secret': 'DJOltEROU1PikRa',
        },
    },
    en : 'https://www.digitalrealty.com',
    de : 'https://www.digitalrealty.de',
    fr : 'https://www.digitalrealty.fr',
    uk: 'https://www.digitalrealty.co.uk',
    nl: 'https://www.digitalrealty.nl',
    kr: 'https://www.digitalrealty.kr',
    cn: 'https://www.digitalrealty.cn',
    jp: 'https://www.digitalrealty.jp',
    asia: 'https://www.digitalrealty.asia',
    es: 'https://www.digitalrealty.es',
    ch: 'https://ch.digitalrealty.com',
    at: 'https://www.digitalrealty.at',
    gr: 'https://www.digitalrealty.gr',
    dk: 'https://www.digitalrealty.dk',
    se: 'https://www.digitalrealty.se',
    be: 'https://www.digitalrealty.be',
    ie: 'https://www.digitalrealty.ie',
}

export const getBackendUrl = (host) => {
    let hostname = 'http://drupal-service:8080';
    let hostnameRemote = 'https://uat-cms.digitalrealty.com';
    let frontendGlobal = 'https://digitalrealty.com/';
    let glossaryRemote = '';

    if(!host){
        return hostnameRemote;
    }
    if(
        host == 'digitalrealty.com' || 
        host === 'www.digitalrealty.com' || 
        host === 'www.digitalrealty.de' || 
        host === 'www.digitalrealty.nl' || 
        host === 'www.digitalrealty.co.uk' ||
        host == 'digitalrealty.de' || 
        host == 'digitalrealty.nl' || 
        host == 'digitalrealty.co.uk' || 
        host == 'digitalrealty.asia' || 
        host == 'digitalrealty.kr' || 
        host == 'www.digitalrealty.asia' || 
        host == 'www.digitalrealty.kr' || 
        host == 'digitalrealty.fr' || 
        host == 'digitalrealty.es' || 
        host == 'www.digitalrealty.fr' || 
        host == 'www.digitalrealty.es' || 
        host == 'www.digitalrealty.at' || 
        host == 'www.ch.digitalrealty.com' || 
        host == 'digitalrealty.at' || 
        host == 'ch.digitalrealty.com' || 
        host == 'www.digitalrealty.ie' || 
        host == 'digitalrealty.ie' || 
        host == 'www.digitalrealty.be' || 
        host == 'www.digitalrealty.gr' || 
        host == 'digitalrealty.be' ||       
        host == 'www.digitalrealty.se' || 
        host == 'digitalrealty.se' ||         
        host == 'www.digitalrealty.dk' || 
        host == 'digitalrealty.dk' ||
        host == 'digitalrealty.cn' ||       
        host == 'www.digitalrealty.cn' ||
        host == 'digitalrealty.gr'        
    ){
        hostnameRemote = 'https://cms.digitalrealty.com';
        frontendGlobal = 'https://digitalrealty.com/';
        glossaryRemote = 'https://cms.digitalrealty.com';
    }else if(host.includes('uat')){
        hostnameRemote = 'https://uat-cms.digitalrealty.com';
        frontendGlobal = 'https://uat.digitalrealty.com/';
        glossaryRemote = 'https://uat-cms.digitalrealty.com';
    }else if(host.includes('localhost')){
        // hostnameRemote = 'https://dlr-backend.getdeveloper.net';
        // hostname = 'https://dlr-backend.getdeveloper.net';
        // frontendGlobal = 'https://dlr-frontend.getdeveloper.net/';
        // glossaryRemote = 'https://dlr-backend.getdeveloper.net';
        hostnameRemote = 'http://html.test';
        hostname = 'http://html.test';
        frontendGlobal = 'http://html.test';
        glossaryRemote = 'http://html.test';
    }else if(host.includes('getdeveloper.net')){
        hostnameRemote = 'https://dlr-backend.getdeveloper.net';
        frontendGlobal = 'https://dlr-frontend.getdeveloper.net/';
        glossaryRemote = 'https://dlr-backend.getdeveloper.net';
    }

    // hostname = 'http://dlr-cms.test';

    return {
        baseUrl: hostname,
        baseApiUrl: `${hostname}/api/`,
        baseApiUrlRemote: `${glossaryRemote}/api/`,
        apiV2Url: `${hostname}/apiv2-dlr/`,
        remoteServer: `${hostnameRemote}/apiv2-dlr/`,
        frontend: frontendGlobal
    };
}
