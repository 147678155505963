import React from "react";
import Link from "next/link";

const HeaderCrawler = ({crawlerMenu}) => {
    function generateHeaderMenu(items) {
        const generateMenuItem = (item) => {
            const children = item?.children?.map(generateMenuItem);
            let link = item.field_go_to_resources === 'On' ? '/resources' : item.link.split('"')[1]

            return (
                <li key={item.id}>
                    {
                        link ?
                        <Link href={link}>{item.name}</Link> :
                        <a>{item.name}</a>
                    }
                    {children?.length > 0 && <ul>{children}</ul>}
                </li>
            );
        };

        const menuItems = items?.map(generateMenuItem);

        return <ul>{menuItems}</ul>;
    }

    return <nav className={'crawler-menu'}>
        {generateHeaderMenu(crawlerMenu)}
    </nav>
}

export default HeaderCrawler
