import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    localStorageRedux: {}
}

export const localStorageSlice = createSlice({
    name: 'localStorageRedux',
    initialState,
    reducers: {
        setLocalStorageRedux: (state, action) => {
            state.localStorageRedux = action.payload
        }
    }
})

export const { setLocalStorageRedux } = localStorageSlice.actions

export default localStorageSlice.reducer