import Image from "next/image";
import { useState } from "react";
import { renderColor } from "../../util/ColorUtil";
import { getEditorColors, isItUndefined, modifyLinks } from "../../util/DataUtil";
import CtaBarButton from "../base/cta-bar-button";
import FacilityModal from "../modal/FacilityModal";
import { cleanHTML } from "../utils/htmlUtils";
const ContentImageRightShape = {
    SQUARE: 0,
    ROUNDED: 1
}
const ContentImageRight = ({children, lang, global}) => {
    const [marketoTrigger, setMarketoTrigger] = useState(false);
    const isRounded = children?.field_image_shape[0]?.value == ContentImageRightShape.ROUNDED;
    const emphasizedTextColor = getEditorColors(isItUndefined(children?.field_formatted_title), isItUndefined(children?.field_description)).filter(x => x)[0];

    return <><div className={`content-image-right ${renderColor(children?.field_text_color[0]?.value)}`}>
        <div className="inner-container">
            <div className="left">
                {isItUndefined(children?.field_formatted_title) ? <span className={`title ${emphasizedTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{__html: cleanHTML(modifyLinks(isItUndefined(children?.field_formatted_title)))}}></span> : <></>}
                {isItUndefined(children?.field_description) ? <span className={`description ${emphasizedTextColor ? emphasizedTextColor : ''}`} dangerouslySetInnerHTML={{__html: cleanHTML(modifyLinks(isItUndefined(children?.field_description)))}}></span> : <></>}
                {children?.field_cta_bar_button_selection?.length > 0 ? <CtaBarButton blockTypeClass={'content-image-right'} setMarketoTrigger={setMarketoTrigger} bgColor={renderColor(children?.field_text_color[0]?.value)} data={children?.field_cta_bar_button_selection[0]?.cta_bar_button} /> : <></>}
            </div>
            <div className={`right ${isRounded ? 'rounded' : ''}`}>
                <Image objectFit="cover" objectPosition={'center'} alt="cloudinary" layout="fill" src={children?.field_image[0]} />
            </div>
        </div>
    </div>
    {marketoTrigger && <FacilityModal global={global} lang={lang}>{children?.field_cta_bar_button_selection[0]?.cta_bar_button?.field_marketo_blocks[0]?.marketo_form_facility_block_type}</FacilityModal>}
    </>
}

export default ContentImageRight;
