import { Modal, Paper } from "@mui/material"
import { useState } from "react";
import Marketo from "../marketo"
import {isItUndefined} from "../../util/DataUtil";

const FacilityModal = ({children, trigger, setTrigger, global, lang}) => {
    const [isSubmitted,setIsSubmitted] = useState(false);

    children = {
        ...children,
        data: {
            trigger,
            setTrigger,
            isSubmitted,
            setIsSubmitted
        },
        field_marketo_form_ids:[{value:isItUndefined(children?.field_marketo_form_ids)}],
        lang
    };

    return(
        <Modal
            open={trigger}
            onClose={() => setTrigger(false)}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            {/*<Paper>*/}
            {/*    <Marketo ctaPopUp={true} close={() => setTrigger(false)} global={global}>{children}</Marketo>*/}
            {/*</Paper>*/}
            <div className={'paper main-marketo'}>
                {/*<Marketo ctaPopUp={true} close={() => setTrigger(false)} global={global}>{children}</Marketo>*/}
                <Marketo lang={lang} ctaPopUp={true} close={() => setTrigger(false)} global={children}>{children}</Marketo>
            </div>
        </Modal>
    )
}

export default FacilityModal;