import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  host: ''
}

export const hostSlice = createSlice({
  name: 'host',
  initialState,
  reducers: {
      setHost: (state, action) => {
          state.host = action.payload
      }
  }
})

export const { setHost } = hostSlice.actions

export default hostSlice.reducer