import { useEffect, useState } from "react";
import { isItUndefined, isMobile, isTablet, modifyLinks } from "../../util/DataUtil";
import { getObjectValueByMachineName } from "../../util/ObjectUtil";
import CtaBarButton from "../base/cta-bar-button";
import { cleanHTML } from "../utils/htmlUtils";

const TemplateTable = ({children, contentType, global, sizeOfScreen}) => {
    const width = 100 / children?.field_table_section?.table_section_paragraph_type[0].field_rows.length;
    const [isScrollMode, setIsScrollMode] = useState(false);
    const lenghtOfRows = children?.field_table_section?.table_section_paragraph_type[0].field_rows.length;
    const scrollableWidth = ((lenghtOfRows - 1) * 384);

    useEffect(() => {
        setIsScrollMode((isTablet(sizeOfScreen) || isMobile(sizeOfScreen)) && isItUndefined(children?.field_is_table_scrollable) === '1'  ? true : false);
    },[])

    const renderRows = (data) => {
        if(!data) return;

        return data.map((e,index) => {
            if(!e.value) return;
            return <span style={{width:`${width}%`}} key={`row-index-${index}`} className="row" dangerouslySetInnerHTML={{__html:modifyLinks(getObjectValueByMachineName(e?.value, global))}} />
        })
    }
    const renderTable = (data) => {
        if(!data) return;
        return data.map((e,key) => {
            if(!e.field_rows) return;

            return <div key={`table-index-${key}`} className={`table-section ${key % 2 == 1 ? 'black' : ''}`}>
                {renderRows(e?.field_rows)}
            </div>
        })
    }

    const renderCtas = (data) => {
        if(!data) return;

        return data.map((e,key) => {
            return <CtaBarButton key={`cta-index-${key}`} data={e?.cta_bar_button} />
        })
    }

    return <div className={`template-table${contentType ? ` ${contentType}` : ''} ${isScrollMode ? `scrollable` : ''}`}>
        <div className="inner-container">
            <span className="table-title" dangerouslySetInnerHTML={{__html: cleanHTML(isItUndefined(children?.field_title_color))}} />
            {isItUndefined(children?.field_description) ? <span className="table-description" dangerouslySetInnerHTML={{__html: cleanHTML(modifyLinks(isItUndefined(children?.field_description)))}} /> : <></>}
            <div className="table-container">
                <div style={{width: isScrollMode ? `${scrollableWidth}px` :''}} className={`table`}>
                    {renderTable(children?.field_table_section?.table_section_paragraph_type)}
                </div>
                {<span className="source-text" dangerouslySetInnerHTML={{__html:cleanHTML(modifyLinks(isItUndefined(children?.field_template_text)))}} />}
                { renderCtas(children?.field_cta_bar_button)?.length > 0 ? <div className="cta-buttons">
                    {renderCtas(children?.field_cta_bar_button)}
                </div> : <></>}
            </div>
        </div>
    </div>
}

export default TemplateTable;