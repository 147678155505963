export const ixnRedirectMessages = {
    en: [
      'Interxion is now Digital Realty.',
      'You are being redirected from the Interxion website as Interxion has rebranded to Digital Realty. Welcome to our fresh new website, with improved features. We hope you enjoy the new website as we remain committed to delivering an exceptional customer experience and service.',
      'Continue to website'
    ],
    nl: [
      'Interxion heet nu Digital Realty.',
      'U wordt doorverwezen van de Interxion-website omdat Interxion is overgegaan naar Digital Realty. Welkom op onze nieuwe website, met verbeterde functies. We hopen dat u veel plezier beleeft aan de nieuwe website, want we blijven ons inzetten voor het leveren van een uitzonderlijke klantervaring en service.',
      'Doorgaan naar website'
    ],
    de: [
      'Interxion ist jetzt Digital Realty.',
      'Sie werden von dieser Website umgeleitet, da Interxion in Digital Realty umfirmiert wurde. Wir hoffen, dass Ihnen die neue Website gefällt und freuen uns, wenn wir Ihnen weiterhin einen außergewöhnlichen Service bieten können.',
      'Weiter zur Website'
    ],
    uk: [
      'Interxion is now Digital Realty.',
      'You are being redirected from the Interxion website as Interxion has rebranded to Digital Realty. Welcome to our fresh new website, with improved features. We hope you enjoy the new website as we remain committed to delivering an exceptional customer experience and service.',
      'Continue to website'
    ],
    fr: [
        'Interxion est devenu Digital Realty.',
        `Vous allez être redirigé depuis le site web d'Interxion, car Interxion a changé de marque pour devenir Digital Realty. Découvrez notre nouveau site web, aux fonctionnalités optimisées. Nous espérons que vous apprécierez ce nouveau site, car nous restons déterminés à offrir à nos clients une expérience et un service exceptionnels.`,
        'Continuer vers le site'
    ],
    ch: [
        'Interxion ist jetzt Digital Realty.',
        'Sie werden von dieser Website umgeleitet, da Interxion in Digital Realty umfirmiert wurde. Wir hoffen, dass Ihnen die neue Website gefällt und freuen uns, wenn wir Ihnen weiterhin einen aussergewöhnlichen Service bieten können.',
        'Weiter zur Website'
    ],
    es: [
        'Interxion es ahora Digital Realty.',
        'Interxion cambia de marca y también de página web: ahora somos Digital Realty. Te damos la bienvenida a nuestra página, con nuevo contenido y mejores funcionalidades. Esperamos que encuentres allí toda la información que necesitas, siguiendo con nuestro compromiso de dar el mejor servicio al cliente.',
        'Ver la nueva web'
    ],
    at: [
        'Interxion ist jetzt Digital Realty',
        'Sie werden auf unsere neue Webseite weitergeleitet, da Interxion mit erstem Jänner 2024 in Digital Realty umfirmiert wurde. Auf unserer neuen Website www.digitalrealty.at finden Sie wie gewohnt alle Informationen und Ansprechpartner zu unseren Produkten und Servives.',
        'Weiter zur Website'
    ],
    dk: [
        'Interxion er nu Digital Realty.',
        'Du bliver omdirigeret fra Interxions hjemmeside, da Interxion er blevet til Digital Realty. Velkommen til vores nye, moderne hjemmeside med bedre funktioner. Vi håber, du vil nyde den nye hjemmeside, da vi fortsat prioriterer at levere en ekstraordinær kundeoplevelse og service.',
        'Fortsæt til hjemmesiden'
    ],
    se: [
        'Interxion är nu Digital Realty.',
        'Du omdirigeras från Interxions webbplats eftersom Interxion nu är Digital Realty. Välkommen till vår nya fräscha webbplats med förbättrade funktioner. Vi hoppas att du gillar den nya webbplatsen eftersom vi är fast beslutna att leverera en exceptionell användarupplevelse och service.',
        'Fortsätt till webbplatsen'
    ],
    ie: [
        'Interxion is now Digital Realty.',
        'You are being redirected from the Interxion website as Interxion has rebranded to Digital Realty. Welcome to our fresh new website, with improved features. We hope you enjoy the new website as we remain committed to delivering an exceptional customer experience and service.',
        'Continue to website'
    ],
    be: [
        'Interxion heet nu Digital Realty.',
        'U wordt doorverwezen van de Interxion-website omdat Interxion is overgegaan naar Digital Realty. Welkom op onze nieuwe website, met verbeterde functies. We hopen dat we u beter van dienst kunnen zijn met de nieuwe website, want we blijven ons inzetten voor het leveren van een uitzonderlijke klantervaring en service.',
        'Doorgaan naar website'
    ],            
  };