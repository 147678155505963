export const getColor = (data) =>{
    let ret = {};

    data.forEach(item => {
        if (item.type !== 'template_banner_block_type'){
            return ''
        }

        ret = item.data.field_banner_color[0]?.value
    })

    return ret;
}

export const getRegionColor = (data) => {
    if(!data){
        return;
    }
    let dataCase;
    if(data.region){
        dataCase = data.region.toLowerCase();
    }
    else if(data.locations){
        dataCase = data.locations.region.toLowerCase();
    }else if(data.content_type === 'region'){
        dataCase = data.field_region_name[0]?.original_tranlsation.toLowerCase();
    }

    let color = 'none';

    switch (dataCase) {
        case 'americas':
        case 'north america':
        case 'south america':
            color = 'americas';
            break;
        case 'emea':
        case 'europe':
        case 'africa':
            color = 'emea';
            break;
        case 'apac':
        case 'asia':
        case 'australia':
            color = 'apac';
            break;
    }
    
    return color;
}

export const renderColor = (brandColour, regionColor) => {
    if(!brandColour && !regionColor) return 'white';

    if(regionColor){
        return regionColor;
    }
    return brandColour.toLowerCase();
}

export const renderStatsBg = (brandColour, regionColor) => {
    if(!brandColour && !regionColor) return 'white';
    if(!brandColour) return regionColor;

    return brandColour?.toLowerCase();
}

export const individualTemplateColor = (data, individualPageForceBackgroundColor) => {
    if(individualPageForceBackgroundColor){
        return '#99EFCB';
    }

    switch (data){
        case '0':
            return '#8AAEFF'
        case '1':
            return '#99EFCB'
        case '2':
            return '#FEEC99'
        case '3':
            return '#99F5FD'
        case '4':
            return '#C999F7'
        case '5':
            return '#9AB5B9'
        case '6':
            return '#C7C8CA'
    }
}

export const brandColor = (data) => {
    switch (data){
        case '0':
            return '#FFFFFF'
        case '1':
            return '#000000'
        case '2':
            return '#222222'
        case '3':
            return '#404040'
        case '4':
            return '#808080'
        case '5':
            return '#C7C8CA'
        case '6':
            return '#F2F2F2'
        case '7':
            return '#DDDDDD'
        case '8':
            return '#1F00FF'
        case '9':
            return '#8AAEFF'
        case '10':
            return '#7700EC'
        case '11':
            return '#C999F7'
        case '12':
            return '#00E5FA'
        case '13':
            return '#99F5FD'
        case '14':
            return '#00D87D'
        case '15':
            return '#99EFCB'
        case '16':
            return '#FCCF00'
        case '17':
            return '#FEEC99'
        case '18':
            return '#01454F'
        case '19':
            return '#9AB5B9'
    }
}

export const getClassTextForColor = (data) => {
    switch (data){
        case '0':
            return 'light'
        case '1':
            return 'dark'
        case '2':
            return 'dark'
        case '3':
            return 'dark'
        case '4':
            return 'light'
        case '5':
            return 'light'
        case '6':
            return 'light'
        case '7':
            return 'light'
        case '8':
            return 'dark'
        case '9':
            return 'light'
        case '10':
            return 'dark'
        case '11':
            return 'light'
        case '12':
            return 'light'
        case '13':
            return 'light'
        case '14':
            return 'light'
        case '15':
            return 'light'
        case '16':
            return 'light'
        case '17':
            return 'light'
        case '18':
            return 'dark'
        case '19':
            return 'light'
    }
}

export const getColorTextForColor = (data) => {
    switch (data){
        case '0':
            return 'light'
        case '1':
            return 'dark'
        case '2':
            return 'dark'
        case '3':
            return 'dark'
        case '4':
            return 'light'
        case '5':
            return 'light'
        case '6':
            return 'light'
        case '7':
            return 'light'
        case '8':
            return 'dark'
        case '9':
            return 'light'
        case '10':
            return 'dark'
        case '11':
            return 'light'
        case '12':
            return 'light'
        case '13':
            return 'light'
        case '14':
            return 'light'
        case '15':
            return 'light'
        case '16':
            return 'light'
        case '17':
            return 'light'
        case '18':
            return 'dark'
        case '19':
            return 'light'
    }
}

export const  extractStyleValue = (str, prop) => {
    const regex = new RegExp(`${prop}\s*:\s*([^;]+)`);
    const match = str.match(regex);
    if(match){
        return match ? match[1].trim() : null;
    }
    return str;
}

export const  extractStyleValueElseNull = (str, prop) => {
    const regex = new RegExp(`${prop}\s*:\s*([^;]+)`);

    const match = str?.match(regex);
    if(match){
        return match ? match[1].trim() : null;
    }

    return null;
}

export const getEditorColor = (value) => {
    if(!value) return;

    value = extractStyleValue(value, 'color');

    switch (value?.toUpperCase()) {
        case '#FFFFFF':
            return 'white';
        case '#000000':
            return 'black';
        case '#222222':
            return 'darker-grey';
        case '#404040':
            return 'dark-grey';
        case '#808080':
            return 'mid-grey';
        case '#C7C8CA':
            return 'light-grey';
        case '#F2F2F2':
            return 'off-white'
        case '#DDDDDD':
            return 'hariline-grey';
        case '#1F00FF':
            return 'blue';
        case '#8AAEFF':
            return 'light-blue';
        case '#7700EC':
            return 'violet';
        case '#C999F7':
            return 'light-violet';
        case '#00E5FA':
            return 'cyan';
        case '#99F5FD':
            return 'light-cyan';
        case '#00D87D':
            return 'green';
        case '#99EFCB':
            return 'light-green';
        case '#FCCF00':
            return 'amber';
        case '#FEEC99':
            return 'light-amber';
        case '#01454F':
            return 'slate-grey';
        case '#9AB5B9':
            return 'light-slate-grey';
        default:
            return null;
    }
}

export const getEditorColorForIcon = (value) => {
    if(!value) return;

    return value.split(':').pop().split(';')[0];
}
