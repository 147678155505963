import React, {useEffect, useState} from "react";
import {Constants, getBackendUrl} from "../util/Constants";
import {getCookie, setCookie, deleteCookie} from 'cookies-next';
import {useRouter} from 'next/router'
import {getGlossaryLanguage, isItUndefined} from "../util/DataUtil";
import { autoPopulateAddActive, handleFocus, translateElements, handleChange } from "../util/MarketoUtil";
import {setMarketoCookieRedux} from "../store/marketoCookieSlice";
import {setVideoPlayingStateRedux} from "../store/gatedVideoPlayingStateSlice";
import { useDispatch, useSelector } from "react-redux";
import { cleanHTML } from "./utils/htmlUtils";

const marketoScriptId = 'mktoForm';

export default function Marketo({ children, close, global, ctaPopUp, setShowDownloadItems, lang }) {
    const {asPath} = useRouter();
    let alias = asPath.substring(1);

    const [glossary, setGlossary] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [isSubmittedLocal, setIsSubmittedLocal] = useState(false);
    const [firstName, setFirstName] = useState(false);
    const [email, setEmail] = useState(false);
    const [marketoCookie, setMarketoCookie] = useState(false);
    const [checked, setChecked] = useState(false);
    const [accessToken, setAccessToken] = useState('');
    const [marketoFolderId, setMarketoFolderId] = useState('');
    const [marketoProgram, setMarketoProgram] = useState('');
    const marketoCookieState = useSelector(setMarketoCookieRedux);
    const videoPlayingState = useSelector(setVideoPlayingStateRedux);
    const dispatch = useDispatch();
    const [isItGated, setIsItGated] = useState(false);
    // const host = getBackendUrl(useSelector((state) => state.host.host));

    let formId = isItUndefined(children?.field_marketo_form_ids);
    let formToken = isItUndefined(global?.field_marketo_form_token);
    let formUrl = isItUndefined(global?.field_marketo_form_url);
    let downloadButtonText = isItUndefined(children?.field_marketo_download_button)

    let templateTitle = '';

    if(typeof document !== "undefined"){
        let item = document.querySelector('.gated_template .template-title .top h1');
        templateTitle = item?.innerText;
    }

    if(typeof window !== 'undefined'){
        var host = getBackendUrl(window.location.host); 
    }

    useEffect(() => {
        if(lang !== 'en' && lang !== undefined){
            fetch(host.baseApiUrlRemote + "glossaries/v1/" + getGlossaryLanguage(lang))
                .then((response) => {
                    if (!response.ok) {
                        return;
                    }
                    return response.json();
                })
                .then((gloss) => {
                    setGlossary(gloss || []);
                });
        }else {
            setGlossary([]);
        }

        if(typeof document !== "undefined"){
            let gated = document.querySelector('.gated_template');

            if(gated !== null){
                setIsItGated(true)
            }
        }
    }, [])

    useEffect(() => {
        const marketoIsSet = getCookie('marketoissetondlr');
        const marketoVals = getCookie('marketovals');

        if(marketoIsSet) {
            setIsSubmitted(marketoIsSet);
        }else {
            deleteCookie('_mkto_trk');
        }
    
        if(marketoVals) {
            setMarketoCookie(JSON.parse(marketoVals));
        }
    }, [isSubmitted]);
    
    useEffect(() => {
        if (typeof window !== 'undefined') {
            let form = window.MktoForms2?.getForm(formId);

            if(marketoCookieState.payload.marketoCookieRedux.marketoCookieRedux.value !== undefined){
                form?.vals(marketoCookieState.payload.marketoCookieRedux.marketoCookieRedux.value)
            }
        }
    }, [marketoCookieState])

    useEffect(() => {
        let fields = [];
        fields.push(document.querySelectorAll('form .mktoField'));

        autoPopulateAddActive(fields)
    })

    function fetchData(props, glossary, lang){
        // let id = marketoScriptId + "_" + formId;
        // let form = typeof  window !== 'undefined' ? document.querySelector('#facility-marketo form') : '';

        useEffect(() => {
            if (!document.getElementById(marketoScriptId)) {
            // if (!document.getElementById(id)) {
            // if (!!document.getElementById(id)) {
            // if (!!document.getElementById(id) && form?.children?.length === 0) {
                loadScript();
            } else {
                setIsLoaded(true);
            }
        }, []);

        useEffect(() => {
            if (typeof window !== 'undefined') {
                (isLoaded && glossary) &&
                window.MktoForms2?.loadForm('//' + formUrl, formToken, formId, function(form){
                    let formHeader;
                    let formHeaderSubtitle;

                    let removeClass = document.querySelectorAll("form .mktoButtonRow .mktoButtonWrap");
                    removeClass.forEach(child => {
                        child.classList.remove('mktoSimple');
                    })

                    if(!ctaPopUp){
                        formHeader = document.querySelector(".form-header .title");
                    }else {
                        formHeader = document.querySelector(".form-header .cta-title");
                    }

                    if(formHeader){
                        formHeader.innerHTML = props.marketo_header_text ?
                            props.marketo_header_text : props.field_marketo_header_text?.length !== 0 ? isItUndefined(props.field_marketo_header_text)
                                : "";
                    }

                    if(!ctaPopUp){
                        formHeaderSubtitle = document.querySelector(".form-header .subtitle");
                    }else {
                        formHeaderSubtitle = document.querySelector(".form-header .cta-subtitle");
                    }

                    if(formHeaderSubtitle){
                        formHeaderSubtitle.innerHTML = props.marketo_subtitle_text ?
                            props.marketo_subtitle_text : props.field_marketo_subtitle_text?.length !== 0 ? isItUndefined(props.field_marketo_subtitle_text)
                                : "";

                        if(formHeaderSubtitle.innerHTML.length === 0){
                            formHeaderSubtitle.style.display = 'none';
                        }
                    }

                    if(formHeader?.innerHTML.length === 0 && formHeaderSubtitle?.innerHTML.length === 0){
                        if(!ctaPopUp){
                            document.querySelector(".form-header").style.display = 'none';
                        }
                        let width = window.innerWidth;

                        if(width <= 992){
                            document.querySelector("form").style.paddingTop = '30px';
                        }
                    }

                    let labels = document.querySelectorAll("form label");
                    let buttons = document.querySelectorAll("form button");
                    let options = document.querySelectorAll("form select option");
                    let span = document.querySelectorAll("form div.mktoHtmlText span>span");
                    let span2 = document.querySelectorAll("form div.mktoHtmlText");
                    let strong = document.querySelectorAll("form strong");

                    let select = document.querySelectorAll("form select");
                    let textarea = document.querySelectorAll("form textarea");
                    let checkbox = document.querySelectorAll("form .mktoCheckboxList label");

                    let downloadButton = document.querySelector("form[id*=" + CSS.escape(formId) + "] .mktoButton");
                    if(downloadButtonText && downloadButton){
                        downloadButton.innerHTML = downloadButtonText;
                    }

                    select.forEach((el) => {
                        let elem = el.parentElement.firstChild;
                        elem.classList.add('active');
                    });

                    // textarea.forEach((el) => {
                    //     let elem = el.parentElement.firstChild;
                    //     elem.classList.add('active');
                    // });

                    checkbox.forEach((el) => {
                        el.classList.add('active');
                    });

                    translateElements(labels, glossary, lang);
                    // translateElements(buttons, glossary, lang);
                    // translateElements(options, glossary, lang);
                    // translateElements(span, glossary, lang);
                    // translateElements(span2, glossary, lang);
                    // translateElements(strong, glossary, lang);

                    let fields = [];
                    fields.push(document.querySelectorAll('form .mktoField'));

                    fields[0].forEach(item => {
                        // item.addEventListener("input", handleChange);
                        item.addEventListener("input", function(event) {
                            handleChange(formId, event);
                        });
                    });

                    // populate form from cookie data
                    if(marketoCookie){
                        autoPopulateAddActive(fields)

                        if(form.submittable()){

                            if(buttons.length === 0){
                                return;
                            }

                            buttons[0].classList.add('active')
                        }
                    }

                    if(!form){
                        return;
                    }

                    if(marketoCookie){
                        delete marketoCookie.formid;
                    }

                    form.vals(marketoCookie);

                    handleFocus(fields, glossary, lang);

                    // SAME EMAIL CHECK - REMOVE THIS BLOCK

                    var formIdInput = document.querySelector('input[name="formid"]');
                    var formIdValue = formIdInput ? formIdInput.value : null;
                    
                    // if (formIdValue === "2870") {
                    if (formIdValue === "2813") {
                        form.onValidate(function (builtInValid) {
                            // if (!builtInValid) return;
                    
                            // form.submittable(true); // refresh previous state
                    
                            var partnerEmailInput = document.querySelector('[name="Partner_Email__c"]');
                            var emailInput = document.querySelector('[name="Email"]');
                            const emailError = document.querySelector('.email-error');

                            if (partnerEmailInput && emailInput) {                                    
                                if(partnerEmailInput.value === emailInput.value){
                                    if(emailError){
                                        emailError.style.display = 'block';    
                                    }
                                    var element = document.getElementById('mktoForm_' +  formId);
                                    window.scrollTo({ top: element , behavior: 'smooth'});

                                    form.submittable(false);
                                }else {
                                    emailError.style.display = 'none';
                                    form.submittable(true);
                                }
                                
                                return;
                            }
                        });
                    }

                    // SAME EMAIL CHECK - REMOVE THIS BLOCK

                    // console.log - onSuccess is for production, onSubmit for localhost
                    form.onSuccess(function(values, followUpUrl) {
                    // form.onSubmit(function(values, followUpUrl) {
                        let vals = form.vals();

                        if(!ctaPopUp){
                            if(!alias){
                                props.data.setTrigger(false);
                                props.data.setIsSubmitted(true);
                                return false;
                            }

                            if(children.data){
                                children.data.setTrigger(false);
                                children.data.setIsSubmitted(true);
                                return false;
                            }
                        }
                        else if(ctaPopUp){
                            children.data.setIsSubmitted(true);
                        }

                        setIsSubmittedLocal(true);
                        setFirstName(vals.FirstName);
                        setEmail(vals.Email)

                        const today = new Date();
                        const tomorrow = new Date(today);
                        tomorrow.setDate(tomorrow.getDate() + 1);
                        let date = Number(isItUndefined(global.field_marketo_cookie_expiration)) !== 0 ? new Date(new Date().getTime() + (Number(isItUndefined(global.field_marketo_cookie_expiration)) + 1) * 60 * 60 * 1000) : tomorrow;

                        setCookie('marketoissetondlr', 'true', { expires: date })
                        setCookie('marketovals', vals, {expires: date});
                        // setCookie('newMarketoVals', vals, {expires: date});

                        localStorage.setItem('newMarketoVals', JSON.stringify(vals));

                        let obj = {
                            'name': 'marketovals',
                            'value': vals
                        }

                        dispatch(setMarketoCookieRedux(obj))

                        let anchorFacility = document.getElementById('facility-marketo')?.offsetTop;
                        let anchorTemplate = document.getElementById('template-marketo')?.offsetTop;
                        let anchorContactInfoTemplate = document.getElementById('template-contact-info')?.offsetTop;

                        if(anchorFacility){
                            window.scrollTo({ top: anchorFacility - 200, behavior: 'smooth'});
                        }else if(anchorTemplate){
                            window.scrollTo({ top: anchorTemplate - 100, behavior: 'smooth'});
                        }else if(anchorContactInfoTemplate){
                            window.scrollTo({ top: anchorContactInfoTemplate - 100, behavior: 'smooth'});
                        }

                        fields[0].forEach(item => {
                            // item.removeEventListener("change", handleChange);
                            item.removeEventListener("input", handleChange);
                        });
                        
                        return false;
                    });
                });
            }

        }, [isLoaded, glossary]);

        const loadScript = () => {
            var s = document.createElement('script');
            s.id = marketoScriptId;
            s.type = 'text/javascript';
            s.async = true;
            s.src = '//app-sj11.marketo.com/js/forms2/js/forms2.min.js';
            s.onreadystatechange = function() {
                if (this.readyState === 'complete' || this.readyState === 'loaded') {
                    setIsLoaded(true);
                }
            };
            s.onload = () => setIsLoaded(true);
            document.getElementsByTagName('head')[0].appendChild(s);
        };

        return isLoaded;
    }

    function addActiveClassToNewElements(){
        let select = document.querySelectorAll(".marketo-form select");
        let textarea = document.querySelectorAll(".marketo-form textarea");
        let checkbox = document.querySelectorAll(".marketo-form .mktoCheckboxList label");

        select.forEach((el) => {
            el.addEventListener('change', (e) => handleClick(e, 'United States'));
            let elem = el.parentElement.firstChild;
            elem.classList.add('active');
        });

        textarea.forEach((el) => {
            let elem = el.parentElement.firstChild;
            elem.classList.add('active');
        });

        checkbox.forEach((el) => {
            el.classList.add('active');
        });
    }

    function handleClick(e, country){
        let result = '';
        for(let item of e.target){
            if(item.innerText === country){
                result = item.innerText;
            }
        }

        addActiveClassToNewElements();
    }

    function addFirstNameAutomatically(data){
        if(!data){
            return;
        }

        let templateTitle = '';

        if(document.querySelector('.template-title h1')){
            templateTitle = document.querySelector('.template-title h1').innerHTML;
        }

        data = data.replace("{first_name}", firstName !== undefined ? firstName : "");
        data = data.replace("{H1}", templateTitle !== undefined ? templateTitle : "");

        return data;
        // return data.replace("{first_name}", firstName !== undefined ? firstName : "");
    }

    function addFirstNameAutomaticallyKnownUser(data){
        let firstNameLocal = '';
        let firstNameLocalNew = '';

        if(!data){
            return;
        }

        if(getCookie('marketovals')){
            firstNameLocal = JSON.parse(getCookie('marketovals'));
        }

        // if(getCookie('newMarketoVals')){
        //     firstNameLocalNew = JSON.parse(getCookie('newMarketoVals'));
        // }

        if(localStorage.getItem('newMarketoVals')){
            firstNameLocalNew = JSON.parse(localStorage.getItem('newMarketoVals'));   
        }

        // let templateTitle = document.querySelector('.template-title h1').innerHTML;

        let templateTitle = '';

        if(document.querySelector('.template-title h1')){
            templateTitle = document.querySelector('.template-title h1').innerHTML;
        }

        // data = data.replace("{first_name}", firstNameLocal.FirstName !== undefined ? firstNameLocal.FirstName : newMarketoVals.FirstName !== undefined ? newMarketoVals.FirstName : "")
        const firstNameToReplace = firstNameLocal.FirstName ?? firstNameLocalNew.FirstName ?? "";

        data = data.replace("{first_name}", firstNameToReplace);
        data = data.replace("{H1}", templateTitle !== undefined ? templateTitle : "");

        // return data.replace("{first_name}", firstNameLocal.FirstName !== undefined ? firstNameLocal.FirstName : "");
        return data;
    }

    function downloadAndEmailFile(hiddenSubmit){
        // let link = "mailto:" + email;
        let file;

        // let formId = isItUndefined(children.field_marketo_form_ids);
        // let formToken = isItUndefined(global.field_marketo_form_token);
        // let formUrl = isItUndefined(global.field_marketo_form_url);

        // if(checked){
        //     window.location.href = link;
        // }

        if(children?.field_marketo_download_file[0]?.value){
            file = children?.field_marketo_download_file[0]?.value
        }

        if(children?.field_marketo_file_url[0]?.value){
            file = children?.field_marketo_file_url[0]?.value;
        }

        if(hiddenSubmit){
            window.MktoForms2.loadForm('//' + formUrl, formToken, formId, function(form){
                form.setValues(marketoCookie)

                if(form.submittable()){
                    form.submit();
                }
            });
        }

        children.data?.setTrigger(false)

        // window.open(file, '_blank');
        if(file){
            window.open(file, '_blank');
        }
    }

    function showDownloadButtonAndCheckbox(text){
        let file;

        if(children?.field_marketo_download_file[0]?.value){
            file = children?.field_marketo_download_file[0]?.value
        }

        if(children?.field_marketo_file_url[0]?.value){
            file = children?.field_marketo_file_url[0]?.value;
        }

        return !!(file && isItUndefined(text));
    }

    function renderSuccessMessage(){
        return(
            <div className={'success-message-header'}>
                <div className={'facility-marketo-success'}>
                    <h1 dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomatically(isItUndefined(children.field_marketo_message_title_text))) }} />
                    <h3 className={'subtitle'} dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomatically(isItUndefined(children.field_marketo_message_subtitle_t))) }} />
                    <div className={'text'} dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomatically(isItUndefined(children.field_marketo_message_body_text))) }} />
                    {
                        showDownloadButtonAndCheckbox(children.field_marketo_message_button_tex) &&
                        <div className={'div-marketo-link downloadButton'} onClick={() => downloadAndEmailFile()} dangerouslySetInnerHTML={{ __html: addFirstNameAutomatically(isItUndefined(children.field_marketo_message_button_tex)) }} />
                    }
                    <div className={'privacy-text'}>
                        <p dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomatically(isItUndefined(children.field_marketo_privacy_text))) }} />
                    </div>
                </div>
            </div>
        );
    }

    function renderKnownUserMessage(){
        return(
            <div className={'success-message-header'}>
                <div className={'facility-marketo-success'}>
                    <h1 dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomaticallyKnownUser(isItUndefined(children.field_marketo_known_user_title))) }} />
                    <h3 className={'subtitle'} dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomaticallyKnownUser(isItUndefined(children.field_marketo_known_user_subtitl))) }} />
                    <div className={'text'} dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomaticallyKnownUser(isItUndefined(children.field_marketo_known_user_message)) )}} />
                    {
                        showDownloadButtonAndCheckbox(children.field_marketo_known_user_button_) &&
                        <div className={'div-marketo-link downloadButton'} onClick={() => downloadAndEmailFile(true)} dangerouslySetInnerHTML={{ __html: addFirstNameAutomaticallyKnownUser(isItUndefined(children.field_marketo_known_user_button_)) }} />
                    }
                    <div className={'privacy-text'}>
                        <p dangerouslySetInnerHTML={{ __html: cleanHTML(addFirstNameAutomaticallyKnownUser(isItUndefined(children.field_marketo_known_user_privacy))) }} />
                    </div>
                </div>
            </div>
        );
    }

    function autoPopulate(){
        return !(children.field_marketo_download_file.length !== 0 || children.field_marketo_file_url.length !== 0);
    }

    function renderMessage(){
        if(isSubmittedLocal){
            if(setShowDownloadItems !== undefined){
                setShowDownloadItems(true)
            }
            {/* DXP-3108 - REMOVE FOR PRODUCTION */}
            return renderSuccessMessage()
            // return videoPlayingState.payload.gatedVideoPlayingStateSlice.videoPlayingStateRedux === false ? renderSuccessMessage() : '';

            // if (isItGated) {
            //     const videoState = videoPlayingState.payload.gatedVideoPlayingStateSlice.videoPlayingStateRedux;
            
            //     if (videoState === false || (typeof videoState !== 'boolean' && Object.keys(videoState).length === 0)) {
            //         return renderSuccessMessage();
            //     }
            
            //     return '';
            // } else {
            //     return renderSuccessMessage();
            // }
        }else if(isSubmitted){
            if(autoPopulate() === false){
                if(setShowDownloadItems !== undefined){
                    setShowDownloadItems(true)
                }
                {/* DXP-3108 - REMOVE FOR PRODUCTION */}
                return renderKnownUserMessage()
                // return videoPlayingState.payload.gatedVideoPlayingStateSlice.videoPlayingStateRedux === false ? renderKnownUserMessage() : '';

                // if (isItGated) {
                //     const videoState = videoPlayingState.payload.gatedVideoPlayingStateSlice.videoPlayingStateRedux;
                
                //     if (videoState === false || (typeof videoState !== 'boolean' && Object.keys(videoState).length === 0)) {
                //         return renderKnownUserMessage();
                //     }
                
                //     return '';
                // } else {
                //     return renderKnownUserMessage();
                // }
            }
        }

        return '';
    }

    function showForm(){
        if(isSubmittedLocal){
            return 'none';
        }

        if(isSubmitted){
            if(!autoPopulate()){
                return 'none';
            }
        }

        return '';
    }

    fetchData(children, glossary, lang);

    return (
        <>
            {
                !ctaPopUp && (!isSubmittedLocal && !isSubmitted) &&
                <div className={'form-header modal-header'}>
                    <div>
                        <p className={'title'} />
                        <p className={'subtitle'} />
                    </div>
                    {
                        ctaPopUp && close !== undefined && <div className={'cancel'} onClick={() => {
                            close()
                        }}>×</div>
                    }
                </div>
            }
            {/* SAME EMAIL CHECK - REMOVE THIS BLOCK */}
            {
                <div className="email-error">
                    Emails cannot match. Provide the customer contact information, including email, in the corresponding fields.
                </div>
            }
            {/* SAME EMAIL CHECK - REMOVE THIS BLOCK */}
            {
                ctaPopUp && (!isSubmittedLocal || !isSubmitted) &&
                <div className={'form-header modal-header'}>
                    <div>
                        <p className={'cta-title'} />
                        <p className={'cta-subtitle'} />
                    </div>
                    {
                        ctaPopUp && close !== undefined && <div className={'cancel'} onClick={() => {
                            close()
                        }}>×</div>
                }
                </div>
            }
            {
                close !== undefined && !ctaPopUp &&
                <div className={'cancel'} onClick={() => {
                    close()
                }}>×</div>
            }
            { renderMessage() }
            <form style={{display: showForm()} } id={'mktoForm_' +  formId } />
        </>
    )
}
