import { createSlice } from '@reduxjs/toolkit'

const initialState = {
  hideBreadCrumbs: false,
  hideFooter: false,
  screenSize: 0,
}

export const pageSlice = createSlice({
  name: 'language',
  initialState,
  reducers: {
        setHideBreadCrumbs: (state, action) => {
          state.hideBreadCrumbs = action.payload
        },
        setHideFooter: (state, action) => {
            state.hideFooter = action.payload
        },
        setScreenSize: (state, action) => {
          state.screenSize = action.payload
        },
    }
})

export const { setHideBreadCrumbs, setHideFooter, setScreenSize } = pageSlice.actions

export default pageSlice.reducer