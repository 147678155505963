import {combineReducers, configureStore} from '@reduxjs/toolkit'
import {createWrapper, HYDRATE} from 'next-redux-wrapper'
import language from './languageSlice'
import host from './hostSlice'
import marketoCookieRedux from './marketoCookieSlice'
import pageSlice from './pageSlice';
import localStorageSlice from "./localStorageSlice";
import gatedVideoPlayingStateSlice from './gatedVideoPlayingStateSlice';

const combinedReducer = combineReducers({
    language,
    host,
    marketoCookieRedux,
    pageSlice,
    localStorageSlice,
    gatedVideoPlayingStateSlice
});

const masterReducer = (state, action) => {
    if (action.type === HYDRATE) {
        return {
            ...state, // use previous state
            language: action.payload.language,
            host: action.payload.host,
            marketoCookieRedux: action.payload.marketoCookieRedux,
            localStorageSlice: action.payload.localStorageSlice,
            gatedVideoPlayingStateSlice: action.payload.gatedVideoPlayingStateSlice
        };
    } else {
    return combinedReducer(state, action)
  }
}

export const makeStore = () =>
  configureStore({
    reducer: masterReducer,
  });

export const wrapper = createWrapper(makeStore, { debug: true });
