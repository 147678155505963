import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    videoPlayingStateRedux: {}
}

export const gatedVideoPlayingStateSlice = createSlice({
    name: 'videoPlayingStateRedux',
    initialState,
    reducers: {
        setVideoPlayingStateRedux: (state, action) => {
            state.videoPlayingStateRedux = action.payload
        }
    }
})

export const { setVideoPlayingStateRedux } = gatedVideoPlayingStateSlice.actions

export default gatedVideoPlayingStateSlice.reducer